import { useState } from "react";

import ReactPlayer from "react-player";

import { Alert, Button, Tooltip } from "antd";
import {
  TbRewindForward30 as Forward30,
  TbRewindBackward30 as Backward30,
} from "react-icons/tb";
import { MdVideoLabel as VideoLabel } from "react-icons/md";

import authApi from "../../api/auth";

export default function SalesVideoPlayer({ salesType, camera }) {
  const [currentClip, setCurrentClip] = useState(camera.duringVideoClip);
  const [currentClipName, setCurrentClipName] = useState("during");

  return (
    <div
      key={`playback-${camera.name}-div`}
      className={
        currentClip.m3u8 === null
          ? "video-clip-error-container"
          : "video-clip-container"
      }
    >
      {!currentClip.m3u8 && (
        <Alert
          className="video-error-message"
          message="Error"
          description="Video Footage Not Found for Deleted Sales Item"
          type="error"
          showIcon
        />
      )}

      {currentClip.m3u8 && (
        <div
          key={`react-player-${camera.mac}-div`}
          className="border-b border-neutral-400 dark:border-neutral-600 rounded-t-md overflow-hidden"
        >
          <ReactPlayer
            key={`react-player-${camera.mac}`}
            height="auto"
            width="100%"
            url={currentClip.url}
            controls
            muted={true}
            loop={false}
            playbackRate={1}
            preload="auto"
            autoPlay={false}
            config={{
              file: {
                forceHLS: true,
                hlsOptions: {
                  xhrSetup: async (xhr) => {
                    const token = await authApi.getAccessToken;
                    xhr.setRequestHeader(
                      "Authorization",
                      `Bearer ${token.customToken}`
                    );
                  },
                  capLevelToPlayerSize: true,
                  maxBufferLength: 60,
                  maxMaxBufferLength: 30,
                  maxBufferSize: 60 * 1000 * 1000,
                  maxBufferHole: 2.5,
                  highBufferWatchdogPeriod: 10,
                  stretchShortVideoTrack: true,
                  maxFragLookUpTolerance: 2.5,
                  enableWorker: true,
                  lowLatencyMode: true,
                  backBufferLength: 90,
                  progressive: true,
                  startLevel: -1,
                  startPosition: 0,
                  testBandwidth: true,
                  liveDurationInfinity: false,
                  fragLoadingTimeOut: 20000,
                  levelLoadingMaxRetry: 10,
                  appendErrorMaxRetry: 10,
                  preferManagedMediaSource: true,
                  enableSoftwareAES: true,
                  frontBufferFlushThreshold: Infinity,
                  autoStartLoad: true,
                  maxLoadingRetry: 1,
                  manifestLoadingTimeOut: 10000,
                  manifestLoadingMaxRetry: 1,
                },
              },
            }}
          />
        </div>
      )}

      <div className="text-center pt-4 pb-2">
        <h3>{camera.name}</h3>
      </div>

      <div className="flex flex-row gap-4 justify-center pb-4">
        <Tooltip
          title={`View 30-second clip from 50-20 seconds before ${salesType}`}
          placement="bottomRight"
          mouseEnterDelay={0.5}
        >
          <Button
            type="text"
            disabled={currentClipName === "before"}
            icon={<Backward30 className="stroke-[1.8px]" size={24} />}
            onClick={() => {
              setCurrentClip(camera.beforeVideoClip);
              setCurrentClipName("before");
            }}
          />
        </Tooltip>

        <Tooltip
          title={`View 40-second clip centered on ${salesType} (±20 seconds)`}
          placement="bottom"
          mouseEnterDelay={0.5}
        >
          <Button
            type="text"
            disabled={currentClipName === "during"}
            icon={<VideoLabel size={24} />}
            onClick={() => {
              setCurrentClip(camera.duringVideoClip);
              setCurrentClipName("during");
            }}
          />
        </Tooltip>

        <Tooltip
          title={`View 30-second clip from 50-20 seconds after ${salesType}`}
          placement="bottomLeft"
          mouseEnterDelay={0.5}
        >
          <Button
            type="text"
            disabled={currentClipName === "after"}
            icon={<Forward30 className="stroke-[1.8px]" size={24} />}
            onClick={() => {
              setCurrentClip(camera.afterVideoClip);
              setCurrentClipName("after");
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
}
