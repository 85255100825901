import authService from "../services/authService";
import axios from "../extension/axios";
import {
  isValidM3U8Playlist,
  translateSalesFilters,
} from "../utils/salesAPIUtils";
import moment from "moment";

const salesAPI = {
  getAllSalesTransactions: async () => {
    let response;

    try {
      response = await axios.get(
        `${authService.getUnitUrl()}sales/transactions`
      );
    } catch (error) {
      let message = "There was an error retrieving all sales transactions.";
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  getFilteredSalesTransactions: async (filters) => {
    const requestBody = translateSalesFilters(filters);

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}sales/transactions`,
        requestBody
      );
    } catch (error) {
      let message = "There was an error retreiving all sales transactions.";
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  getAllDeletedSalesItems: async () => {
    let response;

    try {
      response = await axios.get(
        `${authService.getUnitUrl()}sales/deleted_items`
      );
    } catch (error) {
      let message = "There was an error retrieving all deleted sales items.";
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  checkSalesFootage: async (camera, startTime, endTime) => {
    const start_time = new Date(startTime);
    const end_time = new Date(endTime);

    const date = moment(startTime, "MM/DD/YYYY HH:mm:ss").format("YYYYMMDD");
    const baseURL = `${authService.getUnitUrl()}media/${
      camera.mac
    }/${date}/timelapse.m3u8`;

    const params = new URLSearchParams({
      start_timestamp: moment(start_time).format("YYYY-MM-DDTHH:mm:ss"),
      end_timestamp: moment(end_time).format("YYYY-MM-DDTHH:mm:ss"),
    });

    const url = `${baseURL}?${params.toString()}`;

    try {
      const response = await axios.get(url);

      if (!isValidM3U8Playlist(response.data)) {
        return { data: { url, m3u8: null } };
      }
      return { data: { url, m3u8: response.data } };
    } catch (error) {
      return { data: { url, m3u8: null } };
    }
  },
  uploadSalesTransactions: async (posProvider, file) => {
    const requestFormData = new FormData();
    requestFormData.append("file", file);
    requestFormData.append("pos_provider", posProvider);

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}sales/upload/transactions`,
        requestFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      return { status: false, error: error?.message };
    }

    return { status: true, data: response.data };
  },
  uploadDeletedSalesItems: async (file) => {
    const requestFormData = new FormData();
    requestFormData.append("file", file);

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}/sales/upload/deleted_items`,
        requestFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      return { status: false, error: error?.message };
    }

    return { status: true, data: response.data };
  },
  getPaymentMethods: async () => {
    let response;

    try {
      response = await axios.get(
        `${authService.getUnitUrl()}sales/payment_methods`
      );
    } catch (error) {
      const message = "There was an error retrieving payment methods.";
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
};

export default salesAPI;
