import { useEffect, useState, useCallback } from "react";
import analyticsApi from "../../api/analytics";
import {
  SelectTimePeriodOptions,
  getAPIParameters,
} from "../../utils/analyticsDateUtils";
import {
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Tooltip,
  Rectangle,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Select } from "antd";

import moment from "moment";
import DashboardChartTooltip from "../DashboardChartTooltip";

const PeakHoursChart = ({ selectedDate = new Date() }) => {
  const [chartData, setChartData] = useState({});
  const [weekday, setWeekday] = useState("mondays");
  const [timePeriod, setTimePeriod] = useState("YearToDate");

  const getAPIParametersCallback = useCallback(
    (period) => getAPIParameters(selectedDate)(period),
    [selectedDate]
  );

  useEffect(() => {
    const setHour = () => {
      selectedDate.setHours(23, 59, 59, 999);
    };

    const fetchData = async () => {
      const params = getAPIParametersCallback(timePeriod);

      let response = await analyticsApi.getPeakHours(
        params.startDate,
        params.endDate
      );
      if (response.status) {
        setChartData(response.data);
      }
    };

    setHour();
    fetchData();
  }, [selectedDate, timePeriod, getAPIParametersCallback]);

  const placeholderChartData = () => {
    let data = [];
    let date = new Date();

    for (let h = 6; h < 23; h++) {
      date.setHours(h, 0, 0, 0);
      data.push({ hour: moment(date).format("hA") });
    }

    return data;
  };

  const selectChartData = () => {
    return Object.keys(chartData).length > 0
      ? chartData[weekday]
      : placeholderChartData();
  };

  const createXAxisTicks = () => {
    let ticks = [];

    let openingHour = new Date().setHours(0, 0, 0);
    let closingHour = new Date().setHours(23, 0, 0);

    let currentHour = new Date(openingHour);

    while (currentHour <= closingHour) {
      let date = new Date(currentHour);
      currentHour.setHours(currentHour.getHours() + 1, 0, 0);
      ticks.push(moment(date).format("hA"));
    }

    return ticks;
  };

  const createYAxisTicks = () => {
    const isChartDataEmpty = Object.keys(chartData).length === 0;
    let ticks = [];

    const defaultMaxValue = 20;
    const maxValue = isChartDataEmpty
      ? defaultMaxValue
      : chartData.highestFootTraffic;

    const interval = 5;

    if (maxValue < interval && maxValue > 1) {
      for (let t = 0; t < maxValue + 1; t += 1) {
        ticks.push(t);
      }
    } else if (maxValue <= 1) {
      ticks = [0, 0.2, 0.4, 0.6, 0.8, 1];
    } else {
      const roundedMaxValue = Math.ceil(maxValue / interval) * interval;

      for (let t = 0; t <= roundedMaxValue; t += 5) {
        ticks.push(t);
      }
    }

    return ticks;
  };

  const weekdayOptions = [
    { value: "sundays", label: "Sundays" },
    { value: "mondays", label: "Mondays" },
    { value: "tuesdays", label: "Tuesdays" },
    { value: "wednesdays", label: "Wednesdays" },
    { value: "thursdays", label: "Thursdays" },
    { value: "fridays", label: "Fridays" },
    { value: "saturdays", label: "Saturdays" },
  ];

  const handleTimePeriodChange = (selectedTimePeriod) => {
    setTimePeriod(selectedTimePeriod);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="text-left rounded-md shadow-md bg-neutral-600 pl-3 pr-8 py-1">
          <p className="text-guardis-300">{`${label}`}</p>
          <p className="text-neutral-100 font-medium">{`Average Actions Detected: ${payload[0].payload.averageFootTraffic}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomBarShape = (props) => {
    const { x, y, width, height } = props;

    return (
      <g>
        <defs>
          <linearGradient
            id={"barGradient"}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="25%" stopColor="#4ade80" />
            <stop offset="100%" stopColor="#22c55e" />
          </linearGradient>
        </defs>
        <Rectangle
          x={x}
          y={y}
          width={width}
          height={height}
          fill="url(#barGradient)"
          radius={[width / 3, width / 3, 0, 0]}
        />
      </g>
    );
  };

  return (
    <div className="card grid grid-cols-8 w-full pt-6 px-6 pb-10 mt-2 mb-6">
      <div className="col-span-8 flex justify-center">
        <h2>Peak Hours</h2>
        <DashboardChartTooltip
          className="pl-2"
          title="Visualize Busiest Hours in Selected Time Period"
          content="Calculates average actions detected for each hour and each weekday in a time period."
        />
      </div>
      <div className="col-span-8 flex justify-start gap-6 p-4">
        <div className="flex items-center gap-2 w-80">
          <h3>Weekday</h3>
          <Select
            className="w-full"
            options={weekdayOptions}
            value={weekday}
            onChange={(value) => setWeekday(value)}
          />
        </div>
        <div className="flex items-center gap-2 w-80">
          <h3>Period</h3>
          <Select
            className="w-full"
            options={SelectTimePeriodOptions}
            value={timePeriod}
            placeholder="Select Time Period"
            onChange={handleTimePeriodChange}
          />
        </div>
      </div>
      <div className="col-span-8">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={selectChartData()} barSize={50}>
            <Tooltip cursor={{ fill: "#f1f8f3" }} content={<CustomTooltip />} />

            <CartesianGrid
              vertical={false}
              stroke="#B0B0B0"
              strokeDasharray="4"
              strokeWidth={1.5}
            />

            <Bar
              dataKey="averageFootTraffic"
              yAxisId="left-y-axis"
              shape={<CustomBarShape />}
            />

            <XAxis
              dataKey="hour"
              type="category"
              stroke="#B0B0B0"
              ticks={createXAxisTicks()}
              axisLine={{ strokeWidth: 2 }}
              tickLine={{ strokeWidth: 2 }}
              tickSize={8}
              tickMargin={10}
              interval={1}
            />

            {/* Left YAxis with ticks */}
            <YAxis
              yAxisId="left-y-axis"
              dataKey="averageFootTraffic"
              ticks={createYAxisTicks()}
              stroke="#B0B0B0"
              strokeDasharray="4"
            />

            {/* Right YAxis without ticks */}
            <YAxis
              yAxisId="right-y-axis"
              dataKey="averageFootTraffic"
              orientation="right"
              tick={false}
              tickLine={false}
              stroke="#B0B0B0"
              strokeDasharray="4"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PeakHoursChart;
