import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  LineChartOutlined,
  VideoCameraOutlined,
  PlaySquareOutlined,
  NotificationOutlined,
  BookOutlined,
  SettingOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { TbLogout as LogoutOutlined } from "react-icons/tb";
import { MdOutlineScreenSearchDesktop as SearchDesktop } from "react-icons/md";

import authService from "../../services/authService";
import { navData } from "../../lib/navData";
import logoG from "../../assets/images/logoG.png";
import styles from "./navbar.module.css";

export function Navbar() {
  let cameraList = useSelector((state) => state.camera.cameraList);
  const navigate = useNavigate();

  const topRoutes = [
    {
      key: "navlink-dashboard",
      icon: <LineChartOutlined />,
      text: "Dashboard",
      route: "/",
    },
    {
      key: "navlink-live",
      icon: <VideoCameraOutlined />,
      text: "Live",
      route: "live",
    },
    {
      key: "navlink-playback",
      icon: <PlaySquareOutlined />,
      text: "Playback",
      route: "playback",
    },
    {
      key: "navlink-events",
      icon: <NotificationOutlined />,
      text: "Events",
      route: "events",
    },
    {
      key: "navlink-sales",
      icon: <DollarOutlined />,
      text: "Sales",
      route: "sales",
    },
  ];

  const bottomRoutes = [
    {
      key: "navlink-bookmarks",
      icon: <BookOutlined />,
      text: "Bookmarks",
      route: "bookmarks",
    },
    {
      key: "navlink-setup",
      icon: <SettingOutlined />,
      text: "Setup",
      route: "setup",
    },
    {
      key: "navlink-sign-out",
      icon: <LogoutOutlined size={24} />,
      text: "Sign Out",
      route: "sign-out",
    },
  ];

  const createUrl = (macOfCamera) => {
    const url = `${authService.getUnitUrl()}media/live/${macOfCamera}/output.m3u8`;
    return url;
  };

  const openModalVideo = () => {
    const totalOfCameras = cameraList.length;
    const totalOfRows = Math.round(Math.sqrt(totalOfCameras));
    const totalCamerasByRows = Math.ceil(Math.sqrt(totalOfCameras));

    const videos = cameraList.map((camera) => {
      const videoPath = createUrl(camera.mac);
      return `<div class="col-sm-${
        12 / totalCamerasByRows
      } px-1  m-0"><video class="img-fluid" id="videoPlayer${
        camera.uuid
      }" src=${videoPath} controls autoplay muted  > </video></div>`;
    });

    let startIndex = 0;
    let row = [];

    for (let i = 0; i < totalOfRows; i++) {
      var videosInRow = videos.slice(
        startIndex,
        startIndex + totalCamerasByRows
      );

      if (videosInRow.length < totalCamerasByRows) {
        for (let i = 0; i < totalCamerasByRows - videosInRow.length; i++) {
          videosInRow.push(
            `<div class="col px-1  m-0"><img class="img-fluid" ></div>`
          );
        }
      }

      row.push(`<div class="row"> ${videosInRow.join("")} </div>`);

      startIndex = totalCamerasByRows + startIndex;
    }

    const winHtml = `<!DOCTYPE html>
    <html lang="en" data-headlessui-focus-visible>
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Cameras</title>
      <script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
      <style>
        html{
          height:100%
        }
        body {
          margin:0px!important;
          padding:0px!important;
          line-height: inherit;
          height:100%
        }
        </style>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">
    </head>
    <body class="bg-dark bg-gradient text-white">
    <main>
    <div class="container-fluid mt-2">
    ${row.join("")}
    </div>
    </main>
    <script>
      document.addEventListener('DOMContentLoaded', function () {
        const videoControlList = document.getElementsByTagName("video")
        for (let i = 0; i < videoControlList.length; i++)
       {
        const video  =   videoControlList[i]
        const videoUrl = video.getAttribute('src'); // Replace with the actual URL to your .m3u8 file

        if (Hls.isSupported()) {
          var config = {
            liveDurationInfinity:true,
            highBufferWatchdogPeriod: 6,
            autoStartLoad: true,
            backBufferLength: Infinity,
            frontBufferFlushThreshold: Infinity,
            maxBufferSize: 60 * 1000 * 1000,
            maxBufferHole: 0.5,           
            nudgeOffset: 0.1,
            nudgeMaxRetry: 3,
            maxFragLookUpTolerance: 0.25,
            liveSyncDurationCount: 3,
            liveMaxLatencyDurationCount: Infinity,            
            preferManagedMediaSource: true,
            enableWorker: true,
            enableSoftwareAES: true,
            testBandwidth: true,
            debug: true,
            capLevelToPlayerSize:true,
          };
          const hls = new Hls(config);
          hls.loadSource(videoUrl);
          hls.attachMedia(video);
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = videoUrl;
        }
      };
      });
    </script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
    </body>
    </html>`;

    const winUrl = URL.createObjectURL(
      new Blob([winHtml], { type: "text/html" })
    );

    window.open(
      winUrl,
      "win",
      "toolbar=no, location=no, directories=no, status=no, menubar=no,fullscreen=yes, resizable=yes, scrollbars=no, titlebar=yes"
    );
  };

  const NavLinkClasses = ({ isActive }) => {
    const classNames = "navlink mt-2";
    return `${classNames} ${isActive ? "navlink-active" : "navlink-inactive"}`;
  };

  const NavLinkIconClasses = "text-xl pr-4";

  return (
    <div className="h-full flex flex-col justify-between px-4 py-8">
      <div>
        <div className="h-20 ml-3 mb-6">
          <img
            src={logoG}
            style={{
              objectFit: "contain",
              width: "auto",
              height: "100%",
            }}
            alt="GuardisAI logo"
          />
        </div>

        {topRoutes.map((route) => (
          <NavLink key={route.key} to={route.route} className={NavLinkClasses}>
            <div className={NavLinkIconClasses}>{route.icon}</div>
            {route.text}
          </NavLink>
        ))}

        {/* Monitoring link to new window */}
        <a
          key="navlink-monitoring"
          href="/#"
          className="navlink navlink-inactive mt-2"
          onClick={(e) => {
            e.preventDefault();
            let isUserAuthenticated = authService.isUserAuthenticated();
            if (!isUserAuthenticated) {
              return navigate("/log-in");
            }
            openModalVideo();
          }}
        >
          <div className="pr-3">
            <SearchDesktop size={24} />
          </div>
          Monitoring
        </a>
      </div>
      <div>
        {bottomRoutes.map((route) => (
          <NavLink key={route.key} to={route.route} className={NavLinkClasses}>
            <div className={NavLinkIconClasses}>{route.icon}</div>
            {route.text}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export function MobileNavbar() {
  return (
    <div className="sticky inset-x-0 bottom-0 flex bg-navy px-2 justify-around">
      {navData.map((item) => {
        return (
          <NavLink key={item.id} className={styles.sideitem} to={item.link}>
            {item.icon}
          </NavLink>
        );
      })}
    </div>
  );
}
