import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, ConfigProvider, Drawer, Empty, Spin, Table, Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { FaPlayCircle as PlayFilled } from "react-icons/fa";
import { LuCameraOff as CameraOffFilled } from "react-icons/lu";
import moment from "moment";

import {
  getAllSalesTransactions,
  getFilteredSalesTransactions,
  setFilters,
  clearFilters,
} from "../../store/sales/salesThunk";

import TableFilters from "../TableFilters";
import SalesVideoClips from "../SalesVideoClips";

export default function SalesTransactionsTable() {
  const dispatch = useDispatch();

  const cameras = useSelector((state) => state.camera.cameraList);
  const {
    allTransactions,
    loadingAllTransactions,
    filteredTransactions,
    loadingFilteredTransactions,
    filters,
    registers,
    paymentMethods,
  } = useSelector((state) => state.sales);

  const [salesTransactions, setSalesTransactions] = useState(allTransactions);
  const loadingSalesTransactions =
    loadingAllTransactions || loadingFilteredTransactions;

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [openVideoClipsDrawer, setOpenVideoClipsDrawer] = useState(false);

  const [registerCameras, setRegisterCameras] = useState([]);

  const SALES_FILTERS = {
    register: {
      type: "options",
      label: "Register",
      options: registers?.map((register) => {
        return { value: register.id, label: register.name };
      }),
    },
    timestamp: {
      type: "date",
      label: "Timestamp",
    },
    total: {
      type: "money",
      label: "Total",
    },
    paymentMethod: {
      type: "options",
      label: "Payment Method",
      options: paymentMethods?.map((paymentMethod) => {
        return { value: paymentMethod, label: paymentMethod };
      }),
    },
    numberOfItems: {
      type: "number",
      label: "Number of Items",
    },
  };

  useEffect(() => {
    const setTableSalesTransactions = () => {
      if (filters.length > 0) {
        setSalesTransactions(filteredTransactions);
      } else {
        setSalesTransactions(allTransactions);
      }
    };

    setTableSalesTransactions();
  }, [filters, allTransactions, filteredTransactions]);

  const handleApplyFilters = (newFilters) => {
    dispatch(setFilters(newFilters));
    dispatch(getFilteredSalesTransactions(newFilters));
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
    dispatch(getAllSalesTransactions());
  };

  const getTransactionRegisterCameras = (transaction) => {
    const registerID = transaction.register.id;

    const registerData = registers.find(
      (register) => register.id === registerID
    );

    const registerCamerasList = registerData
      ? registerData.register_cameras
      : [];

    const registerCameraMACs = registerCamerasList
      ? registerCamerasList.map((registerCamera) => {
          return registerCamera.camera_mac;
        })
      : [];

    if (cameras) {
      const transactionRegisterCameras = cameras.filter((camera) =>
        registerCameraMACs.includes(camera.mac)
      );

      setRegisterCameras(transactionRegisterCameras);
    }
  };

  const renderTimestamp = (timestamp) => {
    return (
      <div className="flex flex-wrap gap-1 items-center">
        {moment(timestamp, "YYYY-MM-DD HH:mm:ss").format("MMMM Do YYYY")}
        <div className="dot"></div>
        {moment(timestamp, "YYYY-MM-DD HH:mm:ss").format("h:mm A")}
      </div>
    );
  };

  const renderStatus = (status) => {
    const tagStyles = {
      marginInlineEnd: 0,
      fontSize: 13,
    };

    switch (status) {
      case "Completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success" style={tagStyles}>
            {status}
          </Tag>
        );
      case "Returned":
        return (
          <Tag
            icon={<ExclamationCircleOutlined />}
            color="warning"
            style={tagStyles}
          >
            {status}
          </Tag>
        );
      case "Disputed":
        return (
          <Tag icon={<CloseCircleOutlined />} color="error" style={tagStyles}>
            {status}
          </Tag>
        );
      default:
        return <Tag>{status}</Tag>;
    }
  };

  const salesTransactionColumns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: 100,
      fixed: "left",
      render: (order_id) => <p className="font-mono font-light">{order_id}</p>,
    },
    {
      title: "Register",
      dataIndex: "register",
      key: "register",
      width: 120,
      align: "center",
      render: (register) => register.name,
    },
    {
      title: "Date and Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp) => renderTimestamp(timestamp),
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        moment(a.timestamp, "YYYY-MM-DD HH:mm:ss").valueOf() -
        moment(b.timestamp, "YYYY-MM-DD HH:mm:ss").valueOf(),
    },
    {
      title: "Order Status",
      dataIndex: "status",
      key: "order_status",
      render: renderStatus,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "order_total",
      width: 100,
      render: (total) => <p className="font-mono font-light">${total}</p>,
      sorter: (a, b) => parseFloat(a.total) - parseFloat(b.total),
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Play Video Clip",
      key: "actions",
      width: 160,
      fixed: "right",
      render: (_, transaction) => (
        <Button
          className="text-guardis-400 dark:text-guardis-500"
          type="text"
          icon={<PlayFilled size={18} />}
          onClick={() => {
            setOpenVideoClipsDrawer(true);
            getTransactionRegisterCameras(transaction);
            setSelectedTransaction(transaction);
          }}
        />
      ),
    },
  ];

  const salesItemsColumns = [
    {
      title: "Item UPC",
      dataIndex: "upc",
      key: "item_upc",
      render: (upc) => <p className="font-mono font-light">{upc}</p>,
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "item_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "item_quantity",
      render: (quantity) => <p className="font-mono font-light">{quantity}</p>,
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "item_price",
      render: (price) => <p className="font-mono font-light">${price}</p>,
    },
  ];

  const expandedRowRender = (transaction) => {
    const salesItemsWithKeys = transaction.sales_items.map((item, i) => ({
      ...item,
      key: `${transaction.order_id}-${item.upc}-${i}`,
    }));

    return (
      <Table
        size="small"
        columns={salesItemsColumns}
        dataSource={salesItemsWithKeys}
        pagination={false}
      />
    );
  };

  return (
    <div className="sales-table-container" id="salesTableContainer">
      <div className="sales-table">
        <div className="sales-filters-container">
          <TableFilters
            popoverTitle="Show sales transactions"
            filters={filters}
            applyFilters={handleApplyFilters}
            clearFilters={handleClearFilters}
            filtersConfig={SALES_FILTERS}
          />
        </div>

        <Table
          sticky
          size="middle"
          style={{ width: "100%", maxHeight: "70vh" }}
          scroll={{ x: "max-content", y: "calc(70vh - 10rem)" }}
          pagination={{
            position: ["bottomCenter"],
          }}
          columns={salesTransactionColumns}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => record.sales_items?.length > 0,
          }}
          dataSource={salesTransactions}
          rowKey="order_id"
          locale={{
            emptyText: () => {
              if (loadingSalesTransactions) {
                return <Spin size="large" />;
              }

              return (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No sales transcations found."}
                />
              );
            },
          }}
        />
      </div>

      <Drawer
        classNames={{ body: "p-0 overflow-hidden h-full" }}
        title={
          <div className="flex flex-row gap-3 text-lg">
            <div className="flex gap-1 text-neutral-500 dark:text-neutral-400 font-normal">
              Sales Transcation ID
            </div>
            <RightOutlined className="text-neutral-500 dark:text-neutral-400" />
            <div>{selectedTransaction?.order_id}</div>
          </div>
        }
        size="large"
        open={openVideoClipsDrawer}
        onClose={() => {
          setOpenVideoClipsDrawer(false);
          setSelectedTransaction(null);
          setRegisterCameras([]);
        }}
      >
        <div className="h-full flex flex-col bg-neutral-50 dark:bg-neutral-950">
          {selectedTransaction && (
            <div className="flex flex-row border-b dark:border-neutral-700 shadow-sm bg-white dark:bg-neutral-900">
              <div className="flex flex-col gap-2 p-6 border-r dark:border-neutral-700">
                <div>
                  <h4>Date and Time</h4>
                  <div>{renderTimestamp(selectedTransaction.timestamp)}</div>
                </div>

                <div>
                  <h4>Status</h4>
                  <div>{renderStatus(selectedTransaction.status)}</div>
                </div>

                <div>
                  <h4>Total</h4>
                  <div className="font-mono font-light">
                    ${selectedTransaction.total}
                  </div>
                </div>
              </div>

              <div className="flex flex-1 flex-col gap-2 p-6">
                <h4>Sales Items</h4>
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        cellPaddingBlockSM: 3,
                        cellPaddingInlineSM: 3,
                        fontSize: 13,
                      },
                    },
                  }}
                >
                  <Table
                    className="border dark:border-neutral-700"
                    size="small"
                    bordered
                    columns={salesItemsColumns}
                    dataSource={selectedTransaction.sales_items}
                    pagination={false}
                  />
                </ConfigProvider>
              </div>
            </div>
          )}

          {!cameras && (
            <Empty
              className="py-5 bg-transparent"
              image={
                <CameraOffFilled className="text-neutral-200 text-8xl stroke-1" />
              }
              description={<h3>No Cameras Found</h3>}
            />
          )}

          {cameras && registerCameras.length === 0 && selectedTransaction && (
            <Empty
              className="py-5"
              image={
                <CameraOffFilled className="text-neutral-200 text-8xl stroke-1" />
              }
              description={
                <h3>
                  No Cameras Linked to {selectedTransaction.register.name}
                </h3>
              }
            />
          )}

          {registerCameras.length > 0 && selectedTransaction && (
            <div className="h-full flex-1 overflow-hidden">
              <SalesVideoClips
                salesType="transaction"
                cameras={registerCameras}
                timestamp={moment(
                  selectedTransaction.timestamp,
                  "YYYY-MM-DD HH:mm:ss"
                ).toDate()}
              />
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}
