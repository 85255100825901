import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";

import authService from "../../services/authService";

const NotificationPlayer = ({ notification }) => {
  const [currVidUrl, setCurrVidUrl] = useState(null);

  useEffect(() => {
    setNotification(notification);
  });

  const setNotification = (notification) => {
    if (notification) {
      const streamUrl = `${authService.getUnitUrl()}video_captions/get_video/${
        notification.clip_id
      }`;
      setCurrVidUrl(streamUrl);
    }
  };

  return (
    <div className="flex">
      <ReactPlayer width="100%" height="100%" url={currVidUrl} controls />
    </div>
  );
};

export default NotificationPlayer;
