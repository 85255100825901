import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import LiveVideoPlayer from "../LivePlayer";
import VideoList from "../VideoList";

import authService from "../../services/authService";

export default function LiveFocusView() {
  const cameras = useSelector((state) => state.camera.cameraList);
  const [focusedCamera, setFocusedCamera] = useState(null);

  useEffect(() => {
    if (cameras.length > 0 && focusedCamera === null) {
      setFocusedCamera(cameras[0]);
    }
  }, [cameras, focusedCamera]);

  const createLiveVideoUrl = (mac) => {
    return `${authService.getUnitUrl()}media/live/${mac}/output.m3u8`;
  };

  const onSelectVideo = useCallback(
    (camera) => {
      setFocusedCamera(camera);
    },
    [setFocusedCamera]
  );

  return (
    <div className="w-full h-full flex flex-row overflow-hidden bg-white dark:bg-transparent">
      <div className="w-1/2 flex flex-1 flex-col p-12 min-h-0">
        <div className="flex h-fit max-w-224 rounded-2xl overflow-hidden relative">
          <LiveVideoPlayer
            playerID={`player_${focusedCamera?.mac}`}
            videoURL={createLiveVideoUrl(focusedCamera?.mac)}
            cameraName={focusedCamera?.name}
          />
          <div className="p-2 rounded-lg bg-neutral-900/[0.9] absolute bottom-2 left-2">
            <div className="text-md text-neutral-200 font-medium">
              {focusedCamera?.name}
            </div>
          </div>
        </div>
      </div>

      <div className="w-112 border-l-1.5 dark:border-neutral-800 min-h-0">
        <VideoList
          cameraList={cameras}
          createVideoUrl={createLiveVideoUrl}
          setMainVideo={onSelectVideo}
        />
      </div>
    </div>
  );
}
