import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import analyticsAPI from "../../api/analytics";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
} from "recharts";
import moment from "moment";
import DashboardChartTooltip from "../DashboardChartTooltip";

const MostActiveHourChart = ({ selectedDate = new Date() }) => {
  const [chartData, setChartData] = useState([]);
  const businessHours = useSelector(
    (state) => state.unit.unit.settings.businessHour
  );

  useEffect(() => {
    const setHour = () => {
      selectedDate.setHours(23, 59, 59);
    };

    const fetchData = async () => {
      let startDate = new Date(selectedDate);
      startDate.setDate(startDate.getDate() - 6);
      startDate.setHours(0, 0, 0, 0);

      let response = await analyticsAPI.getMostActiveHours(
        startDate,
        selectedDate
      );

      // check that data isn't an error message
      if (response.status) {
        setChartData(response.data);
      }
    };

    setHour();
    fetchData();
  }, [selectedDate]);

  const formattedChartData = chartData.map((day) => {
    if (day.actions === 0) {
      return {
        timestamp: day.date.getTime(),
        day: moment(day.date).format("dddd"),
      };
    } else {
      return {
        timestamp: day.date.getTime(),
        day: moment(day.date).format("dddd"),
        hour: new Date().setHours(day.date.getHours(), 0, 0),
      };
    }
  });

  const createYAxisTicks = () => {
    let ticks = [];
    let openingHour, closingHour;

    // business hours were set by user
    if (businessHours && businessHours.opens && businessHours.closes) {
      openingHour = moment(businessHours.opens, "HH:00").toDate();
      closingHour = moment(businessHours.closes, "HH:00").toDate();
    }

    // business hours are not set and active hours data was retrieved
    else if (chartData.length !== 0) {
      openingHour = new Date(
        formattedChartData.reduce((min, obj) => {
          return obj.hour && obj.hour < min ? obj.hour : min;
        }, Infinity)
      );

      closingHour = new Date(
        formattedChartData.reduce((max, obj) => {
          return obj.hour && obj.hour > max ? obj.hour : max;
        }, -Infinity)
      );

      // set first and last tick to not equal earliest and latest active hour
      if (openingHour.getHours() !== 0) {
        let newOpeningHour = new Date(openingHour).setHours(
          openingHour.getHours() - 2,
          0,
          0
        );
        openingHour = newOpeningHour;
      }

      if (closingHour.getHours() < 23) {
        let newClosingHour = new Date(closingHour).setHours(
          closingHour.getHours() + 2,
          0,
          0
        );
        closingHour = newClosingHour;
      }
    }

    // business hours are not set and active hours data was NOT received
    else {
      openingHour = new Date().setHours(6, 0, 0);
      closingHour = new Date().setHours(22, 0, 0);
    }

    // generate hourly ticks for chart
    let currentHour = new Date(openingHour);

    while (currentHour <= closingHour) {
      let date = new Date(currentHour);
      ticks.push(date.getTime());
      currentHour.setHours(currentHour.getHours() + 1, 0, 0);
    }

    return ticks;
  };

  const createYAxisDomain = () => {
    let ticks = createYAxisTicks();
    let domain = [ticks.at(0), ticks.at(ticks.length - 1)];

    return domain;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="text-left rounded-md shadow-md bg-neutral-600 pl-3 pr-8 py-1">
          <p className="text-guardis-300">{`${label}`}</p>
          <p className="text-neutral-100 font-medium">{`${moment(
            payload[0].payload.timestamp
          ).format("MMMM Do[,]  h:mm A")}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="card grid grid-cols-8 mt-2 w-full pb-8">
      <div className="col-span-8 py-6 flex justify-center">
        <h2>Most Active Hours in the Past Week</h2>
        <DashboardChartTooltip
          className="pl-2"
          title="Busiest Hours"
          content="Displays the busiest hour (in terms of foot traffic) in the past week from the selected date."
        />
      </div>
      <div className="col-span-12" align="center">
        <ResponsiveContainer width="98%" height={400}>
          <AreaChart
            data={formattedChartData}
            margin={{ top: 20, bottom: 20, left: 50, right: 60 }}
          >
            <defs>
              <linearGradient id="stroke" x1="0" y1="0" x2="1" y2="0">
                <stop offset="50%" stopColor="#D2FB63" stopOpacity={1} />
                <stop offset="100%" stopColor="#33FF8A" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="area" x1="0" y1="0" x2="0" y2="1">
                <stop offset="40%" stopColor="#33FF8A" stopOpacity={1} />
                <stop offset="95%" stopColor="#33FF8A" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="2"
              verticalFill={["#DADADA", "#FFFFFF"]}
              fillOpacity={0.3}
            />
            <Tooltip
              cursor={{
                stroke: "#232255",
                strokeWidth: 2,
              }}
              content={<CustomTooltip />}
            />
            <Area
              dataKey="hour"
              stroke="url(#stroke)"
              strokeWidth={6}
              fill="url(#area)"
              type="monotone"
              connectNulls={true}
              activeDot={{
                stroke: "#FFFFFF",
                strokeWidth: 3,
                fill: "#232255",
                r: 7,
              }}
            />
            <XAxis
              dataKey="day"
              stroke="#B0B0B0"
              type="category"
              axisLine={false}
              tickLine={false}
              tickMargin={20}
            />
            <YAxis
              dataKey="hour"
              width={80}
              interval={2}
              stroke="#B0B0B0"
              ticks={createYAxisTicks()}
              domain={createYAxisDomain()}
              tickFormatter={(timestamp) => moment(timestamp).format("h:mm A")}
              axisLine={false}
              tickLine={false}
              tickMargin={20}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MostActiveHourChart;
