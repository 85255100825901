let messageAPI = null;

export const initializeMessageService = (message) => {
  messageAPI = message;
};

export const messageService = {
  success: (content) => messageAPI?.success(content),
  error: (content) => messageAPI?.error(content),
  info: (content) => messageAPI?.info(content),
  warning: (content) => messageAPI?.warning(content),
};
