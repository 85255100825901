import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Form, Modal, Select } from "antd";

export default function EditRegistersModal({
  registers = [],
  open,
  onOk,
  onCancel,
}) {
  const [form] = Form.useForm();
  const [modalWidth, setModalWidth] = useState("50%");

  const cameras = useSelector((state) => state.camera.cameraList);
  const [initialRegistersData, setInitialRegistersData] = useState([]);
  const [modifiedRegistersData, setModifiedRegistersData] = useState([]);

  useEffect(() => {
    const formatRegisters = () => {
      const formattedRegisters = registers.map((register) => {
        return {
          id: register.id,
          name: register.name,
          cameras: register.register_cameras.map((camera) => {
            return camera.camera_mac;
          }),
        };
      });

      setModifiedRegistersData(formattedRegisters);
      setInitialRegistersData(formattedRegisters);
    };

    formatRegisters();
  }, [registers, open]);

  useEffect(() => {
    const updateModalWidth = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1920) {
        // bigger desktop
        setModalWidth("40%");
      } else if (windowWidth >= 1374) {
        // desktop
        setModalWidth("50%");
      } else if (windowWidth >= 768) {
        // tablets
        setModalWidth("60%");
      } else {
        // mobile
        setModalWidth("95%");
      }
    };

    updateModalWidth();
    window.addEventListener("resize", updateModalWidth);
    return () => window.removeEventListener("resize", updateModalWidth);
  });

  const handleLinkCameraToRegister = (registerID, cameraMACs) => {
    setModifiedRegistersData(
      modifiedRegistersData.map((register) =>
        register.id === registerID
          ? { ...register, cameras: cameraMACs }
          : register
      )
    );
  };

  return (
    <Modal
      id="edit-registers-modal"
      key="edit-registers-modal"
      classNames={{
        mask: "modal-mask",
        header: "modal-header",
        content: "modal-content",
        body: "border-y border-neutral-200 dark:border-neutral-700 h-[50vh]",
        footer: "modal-footer",
      }}
      open={open}
      onOk={() => onOk(initialRegistersData, modifiedRegistersData)}
      onCancel={onCancel}
      title={<h2 className="modal-title">Manage Registers</h2>}
      okText="Save"
      okButtonProps={{
        block: true,
        className: "m-0",
      }}
      cancelButtonProps={{ block: true }}
      destroyOnClose
      centered
      keyboard
      width={modalWidth}
    >
      <div className="h-full flex flex-col">
        <Form
          id="EditRegistersForm"
          form={form}
          preserve={false}
          className="w-full flex flex-col px-8 py-4 gap-4 overflow-y-scroll"
        >
          <h3>Link Cameras to Registers</h3>
          <div
            id="CameraToRegistersMapping"
            className="bg-black/[0.04] dark:bg-white/[0.08] rounded-md"
          >
            {modifiedRegistersData.map((register) => {
              const registerID = register.id;
              const registerName = register.name;
              const registerCameras = register.cameras;

              const key = `link-cameras-to-register-${registerID}`;

              return (
                <div
                  key={key}
                  className="flex flex-row justify-between px-4 py-2 border-b border-neutral-200 dark:border-neutral-800 last:border-b-0"
                >
                  <p className="w-32 text-sm font-normal">{registerName}</p>
                  <Form.Item key={key} className="flex-1">
                    <Select
                      className="w-full"
                      placeholder="Select Cameras to Monitor Register"
                      value={registerCameras}
                      options={cameras}
                      fieldNames={{ label: "name", value: "mac" }}
                      mode="multiple"
                      showSearch
                      variant="filled"
                      onChange={(cameraMACs) =>
                        handleLinkCameraToRegister(registerID, cameraMACs)
                      }
                    />
                  </Form.Item>
                </div>
              );
            })}
          </div>
        </Form>
      </div>
    </Modal>
  );
}
