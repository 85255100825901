import { Button, DatePicker, InputNumber, Select } from "antd";
import { HiOutlineTrash as TrashOutlined } from "react-icons/hi";
import {
  FaDollarSign as DollarSign,
  FaHashtag as Hashtag,
} from "react-icons/fa6";
import dayjs from "dayjs";

const OPERATORS = {
  number: [
    { value: "eq", label: "equals" },
    { value: "neq", label: "does not equal" },
    { value: "gt", label: "greater than" },
    { value: "lt", label: "less than" },
    { value: "gte", label: "greater than or equal" },
    { value: "lte", label: "less than or equal" },
  ],
  money: [
    { value: "eq", label: "equals" },
    { value: "neq", label: "does not equal" },
    { value: "gt", label: "greater than" },
    { value: "lt", label: "less than" },
    { value: "gte", label: "greater than or equal" },
    { value: "lte", label: "less than or equal" },
  ],
  date: [
    { value: "on", label: "on" },
    { value: "before", label: "before" },
    { value: "after", label: "after" },
    { value: "between", label: "between" },
  ],
  options: [
    { value: "eq", label: "is" },
    { value: "neq", label: "is not" },
    { value: "in", label: "is any of" },
  ],
};

export default function TableFilter({
  filter,
  filters = {},
  filterTypes = [],
  onFilterChange,
  onFilterDelete,
  disabledOptions = [],
}) {
  const inputType = filters[filter.key].type;
  const comparisonOperatorOptions = OPERATORS[inputType];

  const handleFilterTypeChange = (filterType) => {
    let updatedFilter = { ...filter };
    updatedFilter.key = filterType;
    updatedFilter.operator = null;
    updatedFilter.value = null;

    onFilterChange(updatedFilter);
  };

  const handleFilterOperatorChange = (filterOperator) => {
    let updatedFilter = { ...filter };
    updatedFilter.operator = filterOperator;
    updatedFilter.value = null;

    onFilterChange(updatedFilter);
  };

  const handleFilterValueChange = (filterValue) => {
    let updatedFilter = { ...filter };
    updatedFilter.value = filterValue;

    onFilterChange(updatedFilter);
  };

  const handleFilterDateChange = (newDate) => {
    let updatedFilter = { ...filter };
    updatedFilter.value = newDate.toDate();

    onFilterChange(updatedFilter);
  };

  const handleFilterDateRangeChange = (newDateRange) => {
    if (newDateRange && newDateRange.length === 2) {
      let updatedFilter = { ...filter };

      const newStartDate = newDateRange[0].toDate();
      const newEndDate = newDateRange[1].toDate();

      updatedFilter.value = [newStartDate, newEndDate];
      onFilterChange(updatedFilter);
    }
  };

  const renderComparisonValueInput = () => {
    switch (inputType) {
      case "number":
        return (
          <InputNumber
            className="w-36"
            value={filter.value}
            addonBefore={<Hashtag size={12} />}
            precision={0}
            onChange={(num) => handleFilterValueChange(num)}
          />
        );
      case "money":
        return (
          <InputNumber
            className="w-36"
            value={filter.value}
            addonBefore={<DollarSign size={12} />}
            controls
            precision={2}
            stringMode
            onChange={(numStr) => handleFilterValueChange(numStr)}
          />
        );
      case "options":
        const selectProps = {
          className: "w-80",
          value: filter.value,
          options: filters[filter.key].options,
          onChange: (values) => handleFilterValueChange(values),
        };

        if (filter.operator === "in") {
          return (
            <Select
              {...selectProps}
              mode="multiple"
              placeholder="Select options"
            />
          );
        }

        return <Select {...selectProps} placeholder="Select option" />;
      case "date":
        const datePickerProps = {
          disabledDate: (date) => {
            return date > dayjs().endOf("day");
          },
        };

        if (filter.operator === "between") {
          return (
            <DatePicker.RangePicker
              {...datePickerProps}
              className="w-96"
              format="MM/DD/YYYY h:mm A"
              showTime
              value={
                Array.isArray(filter.value) && filter.value.length > 0
                  ? [dayjs(filter.value[0]), dayjs(filter.value[1])]
                  : []
              }
              onOk={(newDates) => handleFilterDateRangeChange(newDates)}
            />
          );
        }

        return (
          <DatePicker
            className="w-80"
            placeholder="Select date"
            format="MM/DD/YYYY"
            value={filter.value ? dayjs(filter.value) : null}
            onChange={(newDate) => handleFilterDateChange(newDate)}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="table-filter-container">
      <div className="table-filter-inner-container">
        <h4>Where</h4>

        {/* FILTER TYPE */}
        <Select
          className="w-40"
          value={filter.key}
          options={filterTypes.map((filterType) => {
            if (disabledOptions.includes(filterType.value)) {
              return { ...filterType, disabled: true };
            }
            return filterType;
          })}
          onChange={handleFilterTypeChange}
        />

        {/* FILTER COMPARISON OPERATORS */}
        <Select
          className="w-56"
          placeholder="Select comparison operator"
          value={filter.operator}
          options={comparisonOperatorOptions}
          onChange={handleFilterOperatorChange}
        />

        {/* FILTER COMPARISON VALUE */}
        {renderComparisonValueInput()}
      </div>

      <div className="table-filter-delete-button-container">
        <Button
          className="table-filter-delete-button"
          type="text"
          icon={<TrashOutlined />}
          onClick={() => onFilterDelete()}
        />
      </div>
    </div>
  );
}
