import { Tabs } from "antd";

import {
  MdOutlineViewSidebar as Focus,
  MdOutlineViewModule as Grid,
} from "react-icons/md";

import LiveFocusView from "../components/LiveFocusView";
import LiveGridView from "../components/LiveGridView";

export default function Live() {
  return (
    <div className="live-page-container">
      <div className="live-page-header">
        <h1 className="page-header">Live</h1>
      </div>

      <div className="tabs">
        <Tabs
          items={[
            {
              key: "focus",
              label: (
                <div className="flex flex-row gap-2">
                  <Focus size={24} />
                  Focus View
                </div>
              ),
              children: <LiveFocusView />,
            },
            {
              key: "grid",
              label: (
                <div className="flex flex-row gap-2">
                  <Grid size={24} />
                  Grid View
                </div>
              ),
              children: <LiveGridView />,
            },
          ]}
        />
      </div>
    </div>
  );
}
