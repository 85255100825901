import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import BadgesGraph from "../components/BadgesGraph";
import SalesFunnel from "../components/FunnelGraph";
import ActionsBarChart from "../components/ActionsBarChart";
import CameraActivitySummary from "../components/CameraActivitySummary";
import MostActiveHourChart from "../components/MostActiveHourChart/MostActiveHourChart";
import PeakHoursChart from "../components/PeakHoursChart/PeakHoursChart";
import ActionsChangeChart from "../components/ActionsChangeChart";
import ActionsHeatmap from "../components/ActionsHeatmap";

export default function Analytics() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <div className="page-container flex flex-col gap-6">
      <div className="flex justify-between">
        <h1 className="page-header">Dashboard</h1>
        <DatePicker
          className="w-56"
          size="large"
          label="Date"
          format="MMMM DD, YYYY"
          value={dayjs(selectedDate)}
          onChange={(date) => {
            setSelectedDate(date.toDate());
          }}
          allowClear={false}
          disabledDate={(date) => {
            return date > dayjs().endOf("day");
          }}
        />
      </div>

      <BadgesGraph selectedDate={selectedDate} />
      <SalesFunnel selectedDate={selectedDate} />
      <div className="flex flex-row flex-wrap gap-6">
        <ActionsBarChart selectedDate={selectedDate} />
        <CameraActivitySummary />
      </div>
      <MostActiveHourChart selectedDate={selectedDate} />
      <PeakHoursChart selectedDate={selectedDate} />
      <ActionsChangeChart selectedDate={selectedDate} />
      <ActionsHeatmap selectedDate={selectedDate} />
    </div>
  );
}
