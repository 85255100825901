import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";

import cameraSlice from "./camera/camera";
import unitSlice from "./unit/unit";
import notificationSlice from "./notification/notification";
import lookupSlice from "./lookup/lookup";
import salesSlice from "./sales/salesSlice";

// create reducers
const reducers = combineReducers({
  camera: cameraSlice.reducer,
  notification: notificationSlice.reducer,
  unit: unitSlice.reducer,
  lookup: lookupSlice.reducer,
  sales: salesSlice.reducer,
});

// configure the persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["unit", "camera", "lookup", "sales"],
};

// create root reducer that allow us to clean the store
const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = {};
  }
  return reducers(state, action);
};

// persist the reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: false,
    }).concat(thunk),
});

const persistor = persistStore(store);

export { store, persistor };
