import React from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import ScanResult from "../ScanResult";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useDispatch, useSelector } from "react-redux";
import CameaList from "../CameraList";
import { restartServices } from "../../store/camera/cameraAction";
import { messageService } from "../../services/messageService";

const AddCamerasWizard = (props) => {
  const dispatch = useDispatch();
  const scanCameraList = useSelector((state) => state.camera.scanCameraList);

  const hasAddedCamera = () => {
    const isAnyCameraAdded = scanCameraList?.some((item) => item.status);
    return isAnyCameraAdded;
  };

  const errorMessages = () => {
    messageService.warning("At least one camera must be added to continue.");
  };

  const handleComplete = () => {
    props.addCamerasWizardCallback();
  };

  const handleCancel = () => {
    const isThereNewCameras = hasAddedCamera();

    if (isThereNewCameras) {
      dispatch(restartServices());
    }

    props.addCamerasWizardCallback();
  };

  return (
    <div className="my-8 mx-8 wizard-header">
      <FormWizard
        title={
          <h2>
            <strong>Add Cameras</strong>
          </h2>
        }
        color="#30ac64"
        onComplete={handleComplete}
        stepSize="sm"
        nextButtonTemplate={(handleNext) => (
          <div className="wizard-footer-right">
            <button
              type="button"
              className="custon-wizard-btn custon-wizard-cancel mr-2 text-white font-semibold"
              onClick={handleCancel}
            >
              &nbsp;Finish
            </button>
            &nbsp;
            <button
              className="custon-wizard-btn custon-wizard-next font-semibold"
              onClick={() => {
                const isThereNewCameras = hasAddedCamera();

                if (isThereNewCameras) {
                  dispatch(restartServices());
                }

                handleNext();
              }}
            >
              Next
            </button>
          </div>
        )}
      >
        <FormWizard.TabContent
          title="Select Cameres"
          icon={<VideoCameraFrontIcon />}
        >
          <div className="flex justify-center mb-2">
            <ScanResult></ScanResult>
          </div>
        </FormWizard.TabContent>
        <FormWizard.TabContent
          title="Setup Cameras"
          icon={<SettingsSuggestIcon />}
          isValid={hasAddedCamera()}
          validationError={errorMessages}
        >
          <div className="flex justify-center mb-2">
            <CameaList></CameaList>
          </div>
        </FormWizard.TabContent>
      </FormWizard>
    </div>
  );
};

export default AddCamerasWizard;
