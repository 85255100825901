import { notificationAction } from "./notification";
import notificationApi from "../../api/notification";

import { notificationService } from "../../services/notificationService";

export const setSelectedNotification = (notification) => {
  return (dispatch) => {
    try {
      dispatch(notificationAction.setSelectedNotification(notification));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getNotificationsByfilter = (model) => {
  return async (dispatch) => {
    try {
      dispatch(notificationAction.clearNotifications());
      dispatch(notificationAction.setShowLoadingNotification(true));

      const response = await notificationApi.getNotificationsByfilter(model);

      if (response.status) {
        dispatch(
          notificationAction.setNotifications(response.notification_list)
        );
      } else {
        const response = await notificationApi.getNotificationsByfilter(model);

        if (response.status) {
          dispatch(
            notificationAction.setNotifications(response.notification_list)
          );
        } else {
          notificationService.error({
            message: "Error filtering events",
            description: response.error,
          });
        }
      }

      dispatch(notificationAction.setShowLoadingNotification(false));

      return response.notification_list;
    } catch (err) {
      console.log(err);
      dispatch(notificationAction.setShowLoadingNotification(false));
    }
  };
};

export const getNextNotificatios = () => {
  return async (dispatch) => {
    try {
      dispatch(notificationAction.getNextNotificatios());
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadMoreNotificatios = (model) => {
  return async (dispatch) => {
    try {
      dispatch(notificationAction.setShowLoadingNotification(true));
      const response = await notificationApi.getNotificationsByfilter(model);
      if (response.status) {
        dispatch(
          notificationAction.loadMoreNotificatios(response.notification_list)
        );
      } else {
        notificationService.error({
          message: "Error loading more events",
          description: response.error,
        });
      }
      dispatch(notificationAction.setShowLoadingNotification(false));
    } catch (err) {
      console.log(err);
      dispatch(notificationAction.setShowLoadingNotification(false));
    }
  };
};

export const deleteNotification = (model) => {
  return async (dispatch) => {
    try {
      const response = await notificationApi.deleteNotification(model);
      if (response.status) {
        dispatch(notificationAction.deleteNotification(response.clip_id));
      } else {
        notificationService.error({
          message: "Error when deleting an event",
          description: response.error,
        });
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
};

export const saveUserFeedback = (notification, wasgood) => {
  return async (dispatch) => {
    try {
      const response = await notificationApi.saveUserFeedback(
        notification,
        wasgood
      );
      if (response.status) {
        dispatch(
          notificationAction.updateNotifcationStatus(response.result, wasgood)
        );
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateBookmark = (notification, isBookmark) => {
  return async (dispatch) => {
    try {
      const response = await notificationApi.updateBookmark(
        notification,
        isBookmark
      );
      if (response.status) {
        dispatch(
          notificationAction.updateBookmark({
            clip_id: notification.clip_id,
            isBookmark: isBookmark,
          })
        );
      } else {
        notificationService.error({
          message: "Error updating bookmark",
          description: response.error,
        });
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getBookmarksByFilter = (request) => {
  return async (dispatch) => {
    try {
      dispatch(notificationAction.clearAllBookmarks());
      dispatch(notificationAction.setLoadingAllBookmarks(false));

      const response = await notificationApi.getBookmarksByFilter(request);

      if (response.status) {
        dispatch(notificationAction.setAllBookmarks(response.bookmarks));
      }
    } catch (err) {
      dispatch(notificationAction.setLoadingAllBookmarks(false));
    }
  };
};
