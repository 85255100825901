import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Segmented, Upload } from "antd";

import { FaFileUpload as FileUpload } from "react-icons/fa";

import {
  getAllSalesTransactions,
  getFilteredSalesTransactions,
  getAllDeletedSalesItems,
} from "../../store/sales/salesThunk";
import salesAPI from "../../api/sales";

import { messageService } from "../../services/messageService";

export default function UploadSpreadhseetsModal({ open, onOk, onCancel }) {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.sales);

  const [modalWidth, setModalWidth] = useState("50%");
  const [uploadType, setUploadType] = useState("transactions");

  useEffect(() => {
    const updateModalWidth = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1920) {
        // bigger desktop
        setModalWidth("40%");
      } else if (windowWidth >= 1374) {
        // desktop
        setModalWidth("50%");
      } else if (windowWidth >= 768) {
        // tablets
        setModalWidth("60%");
      } else {
        // mobile
        setModalWidth("95%");
      }
    };

    updateModalWidth();
    window.addEventListener("resize", updateModalWidth);
    return () => window.removeEventListener("resize", updateModalWidth);
  });

  const uploadTransactions = (
    <>
      <Upload.Dragger
        className="upload-spreadsheets-drag"
        accept=".csv,.xls,.xlsx"
        customRequest={async ({ onSuccess, onError, file }) => {
          const response = await salesAPI.uploadSalesTransactions(
            "provider",
            file
          );

          if (response.status) {
            onSuccess("File uploaded succcesfully.");
            messageService.success(`File uploaded successfully.`);

            if (filters.length > 0) {
              dispatch(getFilteredSalesTransactions(filters));
            } else {
              dispatch(getAllSalesTransactions());
            }
          } else {
            const error = new Error(response.error);
            onError({ error }, "File upload failed");
            messageService.error(response.error);
          }
        }}
      >
        <div className="flex flex-col gap-2 py-8">
          <div className="flex justify-center">
            <FileUpload className="upload-spreadsheets-icon" size={36} />
          </div>

          <h3 className="text-neutral-600 dark:text-neutral-400 text-md">
            Upload Sales Transactions Data
          </h3>
          <h4 className="dark:text-neutral-500">
            Drag and drop or click to choose a file
          </h4>
          <h4 className="text-neutral-400">
            Supported formats: XLS, XLSX, CSV
          </h4>
        </div>
      </Upload.Dragger>
    </>
  );

  const uploadDeletedItems = (
    <>
      <Upload.Dragger
        className="upload-spreadsheets-drag"
        accept=".csv,.xls,.xlsx"
        customRequest={async ({ onSuccess, onError, file }) => {
          const response = await salesAPI.uploadDeletedSalesItems(file);

          if (response.status) {
            onSuccess("File uploaded succcesfully.");
            messageService.success(`File uploaded successfully.`);

            dispatch(getAllDeletedSalesItems());
          } else {
            const error = new Error(response.error);
            onError({ error }, "File upload failed");
            messageService.error(response.error);
          }
        }}
      >
        <div className="flex flex-col gap-2 py-8">
          <div className="flex justify-center">
            <FileUpload className="upload-spreadsheets-icon" size={36} />
          </div>

          <h3 className="text-neutral-600 dark:text-neutral-400 text-md">
            Upload Deleted Sales Items Data
          </h3>
          <h4 className="dark:text-neutral-500">
            Drag and drop or click to choose a file
          </h4>
          <h4 className="text-neutral-400">
            Supported formats: XLS, XLSX, CSV
          </h4>
        </div>
      </Upload.Dragger>
    </>
  );

  return (
    <Modal
      id="upload-spreadsheets-modal"
      key="upload-spreadsheets-modal"
      classNames={{
        mask: "modal-mask",
        header: "modal-header",
        content: "modal-content",
        body: "border-y border-neutral-200 dark:border-neutral-700",
        footer: "modal-footer",
      }}
      width={modalWidth}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      title={<h2 className="modal-title">Upload Spreadsheets</h2>}
      footer={null}
      destroyOnClose
      centered
      keyboard
    >
      <div className="upload-spreadsheets-inner-container">
        <Segmented
          value={uploadType}
          options={[
            { value: "transactions", label: "Sales Transactions" },
            { value: "deleted-items", label: "Deleted Sales Items" },
          ]}
          onChange={(value) => setUploadType(value)}
          block
        />

        {uploadType === "transactions"
          ? uploadTransactions
          : uploadDeletedItems}
      </div>
    </Modal>
  );
}
