import { useEffect, useState } from "react";

import { Button, Divider, Dropdown, Popover } from "antd";
import {
  TbFilter as FilterOutlined,
  TbFilterPlus as AddFilterOutlined,
} from "react-icons/tb";

import TableFilter from "../TableFilter";

export default function TableFilters({
  popoverTitle,
  filters,
  applyFilters,
  clearFilters,
  filtersConfig = {},
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(filters);

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  const getDisabledFilters = () => {
    const disabledFilters = [];

    const hasTimestampFilter = currentFilters.some(
      (filter) => filter.key === "timestamp"
    );

    if (hasTimestampFilter) {
      disabledFilters.push("timestamp");
    }
    return disabledFilters;
  };

  const filtersEntries = Object.entries(filtersConfig);
  const filterTypes = filtersEntries.map(([key, value]) => {
    return { value: key, label: value.label };
  });

  const addFilterDropdownItems = filterTypes.map((filterType) => {
    const disabledFilters = getDisabledFilters();

    if (disabledFilters.includes(filterType.value)) {
      return { key: filterType.value, label: filterType.label, disabled: true };
    }
    return { key: filterType.value, label: filterType.label };
  });

  const handleAddFilter = ({ key: filterType }) => {
    const newFilter = {
      id: Date.now(),
      key: filterType,
      operator: null,
      value: null,
    };

    setCurrentFilters([...currentFilters, newFilter]);
  };

  const handleUpdateFilter = (id, updatedFilter) => {
    const updatedFilters = currentFilters.map((filter) =>
      filter.id === id ? updatedFilter : filter
    );

    setCurrentFilters(updatedFilters);
  };

  const handleRemoveFilter = (id) => {
    const newFilters = currentFilters.filter((filter) => filter.id !== id);
    setCurrentFilters(newFilters);
  };

  const handleClearFilters = () => {
    setCurrentFilters([]);
    clearFilters();
  };

  const handleApplyFilters = () => {
    setPopoverOpen(false);
    applyFilters(currentFilters);
  };

  const disabledFilters = getDisabledFilters();

  const disableApplyFilters = () => {
    if (currentFilters.length === 0) {
      return true;
    }

    if (currentFilters.length > 0) {
      const hasNoNulls = currentFilters.every((obj) =>
        Object.values(obj).every((value) => value !== null)
      );

      if (!hasNoNulls) {
        return true;
      }
    }

    return false;
  };
  const disabledApplyFiltersButton = disableApplyFilters();

  const popconfirmContent = (
    <div className="table-filters-popover-container w-full">
      <Divider className="table-filters-popover-divider" />

      <div className="table-filters-popover-filters-container">
        {currentFilters.map((filter) => {
          return (
            <TableFilter
              key={filter.id}
              filter={filter}
              filterTypes={filterTypes}
              filters={filtersConfig}
              onFilterChange={(updatedFilter) =>
                handleUpdateFilter(filter.id, updatedFilter)
              }
              onFilterDelete={() => handleRemoveFilter(filter.id)}
              disabledOptions={disabledFilters}
            />
          );
        })}
      </div>
      <Divider className="table-filters-popover-divider" />

      <Dropdown
        trigger={["click", "contextMenu"]}
        placement="bottom"
        menu={{ items: addFilterDropdownItems, onClick: handleAddFilter }}
        overlayClassName="table-filters-add-filter-button-dropdown"
      >
        <Button
          className="table-filters-add-filter-button"
          type="dashed"
          size="small"
          icon={<AddFilterOutlined size={16} />}
        >
          Add filter
        </Button>
      </Dropdown>

      <Divider className="table-filters-popover-divider" />

      <div className="table-filters-popover-buttons-container">
        <Button
          type="primary"
          size="small"
          onClick={handleApplyFilters}
          disabled={disabledApplyFiltersButton}
        >
          Apply filters
        </Button>

        {currentFilters.length > 0 && (
          <Button type="text" size="small" onClick={handleClearFilters}>
            Clear all filters
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div className="table-filters-container">
      <Popover
        trigger="click"
        open={popoverOpen}
        onOpenChange={(newState) => setPopoverOpen(newState)}
        arrow={false}
        icon={<></>}
        placement="bottomLeft"
        overlayClassName="table-filters-popover"
        title={
          <div className="table-filters-popover-title">{popoverTitle}</div>
        }
        content={popconfirmContent}
      >
        <Button
          className="table-filters-button"
          icon={<FilterOutlined />}
          iconPosition="start"
          type="default"
        >
          Filters
          {currentFilters.length > 0 && <>({currentFilters.length})</>}
        </Button>
      </Popover>
    </div>
  );
}
