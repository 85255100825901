import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Tabs } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PiCashRegister as CashRegisterOutlined } from "react-icons/pi";

import SalesTransactionsTable from "../components/SalesTransactionsTable";
import SalesDeletedItemsTable from "../components/SalesDeletedItemsTable";
import EditRegistersModal from "../components/EditRegistersModal";
import UploadSpreadhseetsModal from "../components/UploadSpreadsheetsModal";

import registersAPI from "../api/registers";

export default function Sales() {
  const { registers } = useSelector((state) => state.sales);

  const [fetchedRegisters, setFetchedRegisters] = useState(registers);
  const [fetchAllRegisters, setFetchAllRegisters] = useState(false);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openEditRegistersModal, setOpenEditRegistersModal] = useState(false);

  useEffect(() => {
    const fetchAllRegisters = async () => {
      if (fetchAllRegisters) {
        setFetchAllRegisters(false);

        const response = await registersAPI.getAllRegisters();
        if (response.status) {
          setFetchedRegisters(response.data);
        }
      }
    };

    fetchAllRegisters();
  }, [fetchAllRegisters]);

  const updateRegisterCameras = async (initialRegisters, modifiedRegisters) => {
    setOpenEditRegistersModal(false);

    const registerCamerasChanges = {};
    modifiedRegisters.forEach((modifiedRegister) => {
      const initialRegister = initialRegisters.find(
        (item) => item.id === modifiedRegister.id
      );

      if (initialRegister) {
        const addedCameras = modifiedRegister.cameras.filter(
          (camera) => !initialRegister.cameras.includes(camera)
        );
        const deletedCameras = initialRegister.cameras.filter(
          (camera) => !modifiedRegister.cameras.includes(camera)
        );

        if (addedCameras.length > 0 || deletedCameras.length > 0) {
          registerCamerasChanges[modifiedRegister.id] = {
            linked: addedCameras,
            unlinked: deletedCameras,
          };
        }
      }
    });

    for (const [registerID, cameraMACs] of Object.entries(
      registerCamerasChanges
    )) {
      const linkedCameraMACs = cameraMACs.linked;
      const unlinkedCameraMACs = cameraMACs.unlinked;

      linkedCameraMACs.forEach(async (cameraMAC) => {
        await registersAPI.linkCameraToRegister(registerID, cameraMAC);
      });

      unlinkedCameraMACs.forEach(async (cameraMAC) => {
        await registersAPI.unlinkCameraToRegister(registerID, cameraMAC);
      });
    }

    setFetchAllRegisters(true);
  };

  return (
    <div className="sales-container">
      <div className="sales-header-container">
        <h1 className="page-header">Sales</h1>

        <div className="flex flex-row gap-8 justify-start">
          <Button
            type="primary"
            icon={<UploadOutlined />}
            iconPosition="end"
            onClick={() => setOpenUploadModal(true)}
          >
            Upload CSV or XLS Files
          </Button>

          <Button
            type="primary"
            icon={<CashRegisterOutlined size={18} />}
            iconPosition="end"
            onClick={() => setOpenEditRegistersModal(true)}
          >
            Manage Registers
          </Button>
        </div>
      </div>

      <div className="tabs">
        <Tabs
          items={[
            {
              key: "1",
              label: "Sales Transactions",
              children: <SalesTransactionsTable />,
            },
            {
              key: "2",
              label: "Deleted Sales Items",
              children: <SalesDeletedItemsTable />,
            },
          ]}
        />
      </div>

      <UploadSpreadhseetsModal
        open={openUploadModal}
        onOk={() => setOpenUploadModal(false)}
        onCancel={() => setOpenUploadModal(false)}
      />

      <EditRegistersModal
        registers={fetchedRegisters}
        open={openEditRegistersModal}
        onOk={updateRegisterCameras}
        onCancel={() => setOpenEditRegistersModal(false)}
      />
    </div>
  );
}
