import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Select,
  TimePicker,
  Button,
  Spin,
  Table,
  Popover,
  Typography,
} from "antd";
import {
  EditFilled,
  SaveOutlined,
  VideoCameraAddOutlined,
  WifiOutlined,
  WarningFilled,
  DeleteFilled,
  HeartFilled,
  UndoOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

import EditCameraModal from "../components/EditCameraModal";
import WarningMessageModal from "../components/WarningMessageModal";

import {
  resetScanCamera,
  removeCamera,
  setLoadingInCameraByMac,
} from "../store/camera/cameraAction";
import { getCameras } from "../store/camera/cameraAction";
import {
  updateUnitSettings,
  setUnitInEditMode,
  setLoadingInUnit,
} from "../store/unit/unitAction";

import ScanModal from "../components/ScanModal/";
import AddCamerasWizard from "../components/AddCameraWizard";
import AddCameraModal from "../components/AddCameraModal";

export default function Setup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let unit = useSelector((state) => state.unit.unit);
  let cameraList = useSelector((state) => state.camera.cameraList);
  let locationList = useSelector((state) => state.unit.locationList);

  const scanModal = useRef();
  const addCameraModal = useRef();

  const [activeMode, setActiveMode] = useState("CameraList");
  const [location, setLocation] = useState(null);
  const [openingHour, setOpeningHour] = useState(null);
  const [closingHour, setClosingHour] = useState(null);
  const [showResetHours, setShowResetHours] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState({});

  const [openEditCameraModal, setOpenEditCameraModal] = useState(false);
  const [openWarningMessageModal, setOpenWarningMessageModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const [scrollY, setScrollY] = useState("calc(83vh - 9rem)");
  const [tableHeight, setTableHeight] = useState("83vh");

  let firstTime = useRef(true);

  const updateCameras = useCallback(async () => {
    dispatch(getCameras());
  }, [dispatch]);

  useEffect(() => {
    const updateTableProperties = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1374) {
        // desktop
        setTableHeight("80vh");
        setScrollY("calc(80vh - 9rem)");
      } else if (windowWidth >= 768) {
        // tablets
        setTableHeight("70vh");
        setScrollY("calc(70vh - 9rem)");
      } else {
        // mobile
        setTableHeight("55vh");
        setScrollY("calc(55vh - 9rem)");
      }
    };

    updateTableProperties();
    window.addEventListener("resize", updateTableProperties);
    return () => window.removeEventListener("resize", updateTableProperties);
  });

  useEffect(() => {
    const convertHourToDate = (timeString) => {
      const result = new Date("2000-01-01 " + timeString);
      return result;
    };

    if (firstTime.current) {
      setActiveMode("CameraList");
      updateCameras();
      firstTime.current = false;

      if (unit.settings.location) {
        setLocation({
          text: unit.settings.location,
          id: unit.settings.location,
        });
      }

      if (unit?.settings?.businessHour?.opens) {
        setOpeningHour(convertHourToDate(unit.settings.businessHour.opens));
      }

      if (unit?.settings?.businessHour?.closes) {
        setClosingHour(convertHourToDate(unit.settings.businessHour.closes));
      }
    }

    return () => {
      dispatch(resetScanCamera());
    };
  }, [navigate, updateCameras, dispatch, unit]);

  const openDeviceModal = () => {
    addCameraModal.current.openDeviceModal();
  };

  const handleChangeUnitLocation = () => {
    const settings = {
      businessHour: {
        opens: dayjs(openingHour).format("HH:mm:ss"),
        closes: dayjs(closingHour).format("HH:mm:ss"),
      },
      location: location.text,
    };

    dispatch(setLoadingInUnit(true));
    dispatch(updateUnitSettings(settings, unit.id === 0)).then(() => {
      dispatch(setLoadingInUnit(false));
      dispatch(setUnitInEditMode(false));
    });
  };

  const handleLocationSelectChange = (selectedOption) => {
    const location = locationList.find(
      (location) => location.id === selectedOption
    );

    setLocation(location);
  };

  const handleScanClick = () => {
    dispatch(resetScanCamera());
    if (scanModal.current) {
      scanModal.current.openModal();
    }
  };

  const scanResultCallback = (result) => {
    if (result) {
      setActiveMode("ScanResult");
    } else {
      dispatch(resetScanCamera());
    }
  };

  const addCamerasWizardCallback = () => {
    setActiveMode("CameraList");
  };

  const handleOpenEditCameraModal = (camera) => {
    setSelectedCamera(camera);
    setOpenEditCameraModal(true);
  };

  const handleOpenWarningMessageModal = (camera) => {
    setSelectedCamera(camera);
    setWarningMessage(
      <div className="flex flex-row leading-4 content-center">
        <p className="text-md font-light">Do you want to remove the camera</p>
        <Typography.Text code className="flex ml-1">
          {camera.name}
        </Typography.Text>
        <p className="text-md font-light">?</p>
      </div>
    );
    setOpenWarningMessageModal(true);
  };

  const handleRemoveCamera = () => {
    setOpenWarningMessageModal(false);

    dispatch(setLoadingInCameraByMac(selectedCamera.mac, true));
    dispatch(removeCamera(selectedCamera)).then(() => {
      dispatch(setLoadingInCameraByMac(selectedCamera.mac, false));
    });

    setSelectedCamera({});
  };

  const cameraTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "cameraName",
      align: "center",
      width: 100,
      fixed: "left",
    },
    {
      title: "Actions",
      dataIndex: "action_types",
      key: "cameraActionTypes",
      width: 400,
      render: (actions) =>
        [...new Set(actions?.map((action) => action?.name))].join(", "),
    },
    {
      title: "Intent",
      dataIndex: "action_types",
      key: "cameraIntent",
      width: 160,
      render: (actions) => (
        <div className="capitalize">
          {[...new Set(actions?.map((action) => action?.intent))].join(", ")}
        </div>
      ),
    },
    {
      title: "Additional Context",
      dataIndex: "additional_context",
      key: "cameraAdditionalContext",
      width: 200,
    },
    { title: "Role", dataIndex: "role", key: "cameraRole", width: 140 },
    {
      title: "IP Address",
      dataIndex: "ip",
      key: "cameraIPAddress",
      width: 140,
    },
    { title: "Port", dataIndex: "port", key: "cameraPort", width: 80 },
    {
      title: "Mac Address",
      dataIndex: "mac",
      key: "cameraMacAddress",
      width: 160,
    },
    {
      title: "Vendor",
      dataIndex: "vendorName",
      key: "cameraVendor",
      width: 160,
    },
    {
      title: "Status",
      dataIndex: "ishealthy",
      key: "cameraStatus",
      align: "center",
      width: 100,
      fixed: "right",
      render: (status) =>
        status ? (
          <HeartFilled className="text-lg text-guardis-500 " />
        ) : (
          <Popover content="There is an issue with this camera.">
            <WarningFilled className="text-lg text-red-500" />
          </Popover>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, camera) => (
        <div className="flex flex-row gap-2 justify-center">
          <Button
            icon={<EditFilled />}
            iconPosition="start"
            onClick={() => handleOpenEditCameraModal({ ...camera })}
          ></Button>
          <Button
            danger
            icon={<DeleteFilled />}
            iconPosition="start"
            onClick={() => handleOpenWarningMessageModal({ ...camera })}
          ></Button>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full py-8 px-10 space-y-8">
      <h1 className="page-header">Camera Setup</h1>

      {activeMode === "CameraList" && (
        <>
          <div className="flex flex-wrap items-end justify-between gap-5">
            <div className="flex flex-wrap items-end justify-between gap-4">
              <div className="w-60">
                <h3 className="mr-2">Unit Location</h3>
                <Select
                  className="w-full"
                  fieldNames={{ label: "text", value: "id" }}
                  placeholder={"Select a location option"}
                  value={location ? location.id : null}
                  options={locationList}
                  onChange={handleLocationSelectChange}
                  showSearch
                />
              </div>

              <div className="w-60">
                <h3 className="mr-2">Business Hours</h3>
                <TimePicker.RangePicker
                  className="w-full"
                  use12Hours
                  format="h:mm A"
                  needConfirm={false}
                  allowClear={false}
                  value={
                    openingHour && closingHour
                      ? [dayjs(openingHour), dayjs(closingHour)]
                      : []
                  }
                  onChange={(newValues) => {
                    setShowResetHours(true);

                    const newOpenValue = newValues[0].toDate();
                    const newCloseValue = newValues[1].toDate();

                    setOpeningHour(newOpenValue);
                    setClosingHour(newCloseValue);
                  }}
                />
              </div>

              <div className="flex gap-2 items-center w-28">
                <Button
                  type="primary"
                  icon={<UndoOutlined />}
                  disabled={!showResetHours}
                  onClick={() => {
                    const convertToDateObject = (timeString) => {
                      const date = new Date();
                      const [hour, minutes, seconds] = timeString.split(":");

                      date.setHours(Number(hour));
                      date.setMinutes(Number(minutes));
                      date.setSeconds(Number(seconds));

                      return date;
                    };

                    const businessHours = unit?.settings?.businessHour;

                    if (businessHours) {
                      setOpeningHour(convertToDateObject(businessHours.opens));
                      setClosingHour(convertToDateObject(businessHours.closes));
                    }

                    setShowResetHours(false);
                  }}
                />
                <Button
                  className=""
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    handleChangeUnitLocation(unit);
                    setShowResetHours(false);
                  }}
                />
                <Spin spinning={unit.loading} aria-label="Loading Spinner" />
              </div>
            </div>

            <div className="flex flex-wrap gap-2">
              <Button
                className="mr-4"
                type="primary"
                icon={<VideoCameraAddOutlined />}
                iconPosition="end"
                onClick={openDeviceModal}
              >
                Add Camera Device
              </Button>
              <Button
                type="primary"
                icon={<WifiOutlined />}
                iconPosition="end"
                onClick={handleScanClick}
              >
                Scan for Camera Devices
              </Button>
            </div>
          </div>

          <Table
            className="tables"
            bordered
            sticky
            style={{ width: "100%", height: tableHeight }}
            scroll={{ x: "max-content", y: scrollY }}
            columns={cameraTableColumns}
            dataSource={cameraList}
            pagination={{
              responsive: true,
              position: ["bottomCenter"],
              pageSize: 10,
            }}
            rowKey={(camera) => camera.uuid}
          />

          <AddCameraModal ref={addCameraModal} />
          <ScanModal ref={scanModal} ScanResultCallback={scanResultCallback} />

          <EditCameraModal
            camera={selectedCamera}
            open={openEditCameraModal}
            onOk={() => setOpenEditCameraModal(false)}
            onCancel={() => setOpenEditCameraModal(false)}
          />
        </>
      )}

      {activeMode === "ScanResult" && (
        <>
          <AddCamerasWizard
            addCamerasWizardCallback={addCamerasWizardCallback}
          ></AddCamerasWizard>
        </>
      )}

      <WarningMessageModal
        message={warningMessage}
        open={openWarningMessageModal}
        onOk={handleRemoveCamera}
        onCancel={() => setOpenWarningMessageModal(false)}
      />
    </div>
  );
}
