import { useSelector } from "react-redux";

import authService from "../../services/authService";
import LiveVideoPlayer from "../LivePlayer";

export default function LiveGridView() {
  const cameras = useSelector((state) => state.camera.cameraList);

  const createLiveVideoURL = (mac) => {
    return `${authService.getUnitUrl()}media/live/${mac}/output.m3u8`;
  };

  return (
    <div className="h-full w-full p-4 bg-white dark:bg-transparent overflow-y-scroll">
      <div className="grid grid-cols-2 2xl:grid-cols-4 auto-rows-max">
        {cameras.map((camera, index) => {
          const videoURL = createLiveVideoURL(camera.mac);

          return (
            <div className="m-2">
              <div className="h-fit rounded-xl overflow-hidden relative">
                <LiveVideoPlayer
                  playerID={`player_${camera.mac}`}
                  videoURL={videoURL}
                  cameraName={camera.name}
                />
                <div className="px-2 py-1 rounded-lg bg-neutral-900/[0.9] absolute bottom-2 left-2">
                  <div className="text-sm text-neutral-200 font-medium">
                    {camera.name}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
