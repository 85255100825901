import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import axios from "../extension/axios";

import authService from "../services/authService";
import TimeLine from "../components/TimeLine";
import VideoList from "../components/VideoList";
import notificationApi from "../api/notification";
import NotificationModal from "../components/NotificationModal";

import PauseCircleRounded from "@mui/icons-material/PauseCircleRounded";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import SlowMotionVideoRoundedIcon from "@mui/icons-material/SlowMotionVideoRounded";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPreviousRounded";
import SkipNextIcon from "@mui/icons-material/SkipNextRounded";
import { RightOutlined } from "@ant-design/icons";
import "react-image-gallery/styles/css/image-gallery.css";

const Playback = React.memo(() => {
  const currentVideoPlayer = useRef(null);
  const notificationModalControl = useRef(null);
  const timelineRef = useRef(null);
  const selectedDateRef = useRef(new Date());
  const firstRender = useRef(true);
  const periodsWithoutVideo = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cameraList = useSelector((state) => state.camera.cameraList);
  const [currCamera, setCurrCamera] = useState({ namr: "" });
  const [currVidUrl, setCurrVidUrl] = useState(null);
  const [timeRange, setTimeRange] = useState([0, 70]);
  const [play, setPlay] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);

  const processVideo = useCallback(async (streamUrl, camera) => {
    const getVideoDetails = async (streamUrl) => {
      const fileData = await getVideoFileFromSource(streamUrl);
      if (!fileData) {
        return {
          startTimeMinutesOfVideo: 0,
          endTimeMinutesOfVideo: 0,
          periodsInVideo: [],
        };
      }

      const segmenstInPlayList = await getSementsDateTimeFromFileContent(
        fileData
      );

      let periodsInVideo = [];
      let startPeriod = segmenstInPlayList[0];
      const gapLimiteInSeconds = 20;

      segmenstInPlayList.forEach((currentTime, index) => {
        const nextTime = segmenstInPlayList[index + 1];
        if (!nextTime) return; // Skip last iteration

        const diffInSeconds = (nextTime - currentTime) / 1000;

        if (diffInSeconds > gapLimiteInSeconds) {
          const endPeriod =
            startPeriod === currentTime ? nextTime : currentTime;
          periodsInVideo.push({ startPeriod, endPeriod });
          startPeriod = nextTime;
        }
      });

      // Add the last period.
      if (segmenstInPlayList.length > 0) {
        periodsInVideo.push({
          startPeriod,
          endPeriod: segmenstInPlayList[segmenstInPlayList.length - 1],
        });
      }

      const startTimeMinutesOfVideo = getMinutesFromDate(segmenstInPlayList[0]);
      const endTimeMinutesOfVideo = getMinutesFromDate(
        segmenstInPlayList[segmenstInPlayList.length - 1]
      );

      return { startTimeMinutesOfVideo, endTimeMinutesOfVideo, periodsInVideo };
    };

    const videoDetails = await getVideoDetails(streamUrl);

    const eventModel = {
      camera_id: camera.mac,
      start_time: moment(selectedDateRef.current).format("YYYY-MM-DD 00:00:00"),
      end_time: moment(selectedDateRef.current).format("YYYY-MM-DD 23:59:59"),
    };

    const result = await notificationApi.getNotificationsByfilter(eventModel);

    const data = result.notification_list.map((notification, index) => {
      const startDate = new Date(notification.sent_date);

      return {
        id: index,
        start: startDate,
        title: notification.actionsDetected,
        align: "center",
        selectable: true,
        actionsDetected: notification.actionsDetected,
        description: notification.description,
        cameraname: getCameraName(notification.camera_id),
        totalOfActionsDetected: notification.totalOfActionsDetected,
        intentDetected: notification.intentDetected,
        sent_date: notification.sent_date,
        clip_id: notification.clip_id,
        camera_id: notification.camera_id,
      };
    });

    const periodsInVideo = createClipsOfCurrentVideo(
      videoDetails.periodsInVideo,
      camera.mac
    );

    if (timelineRef.current) {
      const gapPeriods = calculateGaps(periodsInVideo);

      timelineRef.current.setPeriods(
        [...gapPeriods],
        selectedDateRef.current,
        data
      );

      periodsWithoutVideo.current = gapPeriods;
    }

    return videoDetails;
  }, []);

  const setMainVideo = useCallback(
    async (camera) => {
      const streamUrl = createVideoUrl(camera.mac);

      setTimeout(async () => {
        const videoData = await processVideo(streamUrl, camera);
        setVideoTimeSpan(videoData);
      }, 500);

      setCurrCamera(camera);
      setCurrVidUrl(streamUrl);
    },
    [processVideo]
  );

  useEffect(() => {
    if (firstRender.current && cameraList.length > 0) {
      setMainVideo(cameraList[0]);
      firstRender.current = false;
    }
  }, [navigate, setMainVideo, cameraList, dispatch]);

  const setVideoTimeSpan = (videoDetails) => {
    const videoDurationInMinutes = Math.round(
      videoDetails.startTimeMinutesOfVideo
    );
    const endTimeMinutes = Math.round(videoDetails.endTimeMinutesOfVideo);

    setTimeRange([videoDurationInMinutes, endTimeMinutes]);
    setPlaybackRate(1);
  };

  const getCameraName = (cameraId) => {
    const camera = cameraList.find((c) => c.mac === cameraId);
    return camera ? camera.name : "Generic";
  };

  const createVideoUrl = (macOfCamera) => {
    const yyyyMMdd = moment(selectedDateRef.current).format("YYYYMMDD");
    return `${authService.getUnitUrl()}media/${macOfCamera}/${yyyyMMdd}/output.m3u8`;
  };

  const handleDateChange = (date) => {
    selectedDateRef.current = date;
    setMainVideo(currCamera);
  };

  const handleTimeChange = (newTime) => {
    let seletedTimeInSeconds = newTime * 60;
    let lostTimeForGapsInseconds = getTimeGapsInVideoForSpecificMoment(
      seletedTimeInSeconds,
      true
    );

    if (lostTimeForGapsInseconds > 0) {
      seletedTimeInSeconds = seletedTimeInSeconds - lostTimeForGapsInseconds;
    }

    if (currentVideoPlayer.current) {
      currentVideoPlayer.current.seekTo(seletedTimeInSeconds, "seconds");
    }
  };

  const handleProgress = (progress) => {
    let secondsCurrentTimeInProgress = progress.playedSeconds;

    let lostTimeForGapsInseconds = getTimeGapsInVideoForSpecificMoment(
      secondsCurrentTimeInProgress
    );

    if (lostTimeForGapsInseconds > 0) {
      secondsCurrentTimeInProgress =
        secondsCurrentTimeInProgress + lostTimeForGapsInseconds;
    }

    setCurrentTime(secondsCurrentTimeInProgress);

    if (timelineRef.current) {
      timelineRef.current.setCurrentTime(secondsCurrentTimeInProgress);
      setPlay(true);
    }
  };

  const createClipsOfCurrentVideo = (periodsInVideo) => {
    return periodsInVideo.map((period, index) => ({
      id: index,
      start: moment(period.startPeriod).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(period.endPeriod).format("YYYY-MM-DD HH:mm:ss"),
    }));
  };

  const valueLabelFormat = (value) => {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const setVideoSpeed = (speed) => {
    const currentTimeInVideo = currentVideoPlayer.current.getCurrentTime();
    const newTime = Math.max(currentTimeInVideo + speed, 0);

    if (newTime <= timeRange[1] * 60) {
      currentVideoPlayer.current.seekTo(newTime);
    }
  };

  const changeCamera = (direction) => {
    const index = cameraList.findIndex(
      (camera) => camera.uuid === currCamera.uuid
    );
    const newIndex = direction === "next" ? index + 1 : index - 1;

    if (newIndex >= 0 && newIndex < cameraList.length) {
      setMainVideo(cameraList[newIndex]);
      setCurrCamera(cameraList[newIndex]);
    }
  };

  const getVideoFileFromSource = async (url) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching the file:", error);
      return null;
    }
  };

  const getSementsDateTimeFromFileContent = (textContent) => {
    const result = textContent.split("\n").reduce((tsFileList, line) => {
      if (line.includes("#EXT-X-PROGRAM-DATE-TIME")) {
        const timeMatch = line.match(
          /#EXT-X-PROGRAM-DATE-TIME:\s*([^]+?)(?=#EXT-X-PROGRAM-DATE-TIME:|$)/
        );
        if (timeMatch && timeMatch[1]) {
          const timeSpan = new Date(timeMatch[1]);
          tsFileList.push(timeSpan);
        }
      }

      return tsFileList;
    }, []);

    return result.sort((a, b) => a - b);
  };

  const getMinutesFromDate = (date) => {
    const startHours = date.getHours();
    const startMinutes = date.getMinutes();
    const startSeconds = date.getSeconds();

    return startHours * 60 + startMinutes + startSeconds / 60;
  };

  const calculateGaps = (intervals) => {
    const gaps = [];
    const startDayPeriod = new Date(
      moment(intervals[0].start).format("YYYY-MM-DD 00:00:00")
    );
    const startDateTimeInVideoPeriod = new Date(
      moment(intervals[0].start).format("YYYY-MM-DD HH:mm:ss")
    );

    const startDelay = startDateTimeInVideoPeriod - startDayPeriod;

    if (startDelay > 0) {
      gaps.push({
        start: moment(startDayPeriod).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(startDateTimeInVideoPeriod).format("YYYY-MM-DD HH:mm:ss"),
        durationInSeconds: startDelay / 1000,
      });
    }

    for (let i = 0; i < intervals.length - 1; i++) {
      const endOfCurrent = new Date(intervals[i].end);
      const startOfNext = new Date(intervals[i + 1].start);
      const gapMilliseconds = startOfNext - endOfCurrent;

      if (gapMilliseconds > 0) {
        gaps.push({
          start: intervals[i].end,
          end: intervals[i + 1].start,
          durationInSeconds: gapMilliseconds / 1000,
        });
      }
    }
    return gaps;
  };

  const getTimeGapsInVideoForSpecificMoment = (
    currentTimeInSeconds,
    isForSpecificMoment = false
  ) => {
    let result = 0;
    let currentTimeInMilliseconds = currentTimeInSeconds * 1000;

    periodsWithoutVideo.current.forEach((period) => {
      const startPeriodDate = new Date(period.start);
      const endPeriodDate = new Date(period.end);

      const startPeriod = startPeriodDate.getHours();
      const startPeriodMinutes = startPeriodDate.getMinutes();
      const startPeriodSeconds = startPeriodDate.getSeconds();

      const endPeriod = endPeriodDate.getHours();
      const endPeriodMinutes = endPeriodDate.getMinutes();
      const endPeriodSeconds = startPeriodDate.getSeconds();

      const starPeriodtInMilliseconds =
        startPeriod * 60 * 60 * 1000 +
        startPeriodMinutes * 60 * 1000 +
        startPeriodSeconds * 1000;

      const endPeriodInMilliseconds =
        endPeriod * 60 * 60 * 1000 +
        endPeriodMinutes * 60 * 1000 +
        endPeriodSeconds * 1000;

      if (
        (currentTimeInMilliseconds > starPeriodtInMilliseconds &&
          currentTimeInMilliseconds > endPeriodInMilliseconds) ||
        (currentTimeInMilliseconds > starPeriodtInMilliseconds &&
          currentTimeInMilliseconds < endPeriodInMilliseconds)
      ) {
        result = result + period.durationInSeconds;

        if (!isForSpecificMoment) {
          currentTimeInMilliseconds =
            currentTimeInMilliseconds + period.durationInSeconds * 1000;
        }
      }
    });

    return result;
  };

  const handleSelectedEven = (openModal) => {
    notificationModalControl.current.openModal(openModal);
  };

  return (
    <div className="h-full flex flex-row justify-between">
      <div className="max-w-[65vw] flex flex-1 flex-col gap-4 px-10 py-8">
        <h1 className="page-header mb-2">Playback</h1>

        <div className="h-full flex flex-col gap-2 px-8 py-4 bg-white dark:bg-neutral-900 border dark:border-neutral-700 overflow-y-scroll">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-2 text-lg">
              <div className="text-neutral-400 dark:text-neutral-500">
                Selected Camera
              </div>
              <RightOutlined className="text-neutral-300 dark:text-neutral-500" />
              <div className="text-guardis-600 dark:text-guardis-500">
                {currCamera?.name}
              </div>
            </div>

            <DatePicker
              className="w-56"
              label="Date"
              format="MMMM DD, YYYY"
              value={dayjs(selectedDateRef.current)}
              onChange={(newDate) => handleDateChange(newDate.toDate())}
              disabledDate={(date) => {
                return date > dayjs().endOf("day");
              }}
            />
          </div>

          <div className="flex flex-col justify-center overflow-hidden bg-stone-100 dark:bg-guardis-950 border-2 border-stone-200 dark:border-guardis-900">
            {/* ReactPlayer */}
            <div className="border-b-2 border-stone-200 dark:border-guardis-900">
              <ReactPlayer
                height="auto"
                width="100%"
                ref={currentVideoPlayer}
                onProgress={handleProgress}
                id="main"
                url={currVidUrl}
                controls
                playing={play}
                muted={true}
                playbackRate={playbackRate}
                loop={false}
                preload="auto"
                autoPlay={true}
                config={{
                  file: {
                    hlsOptions: {
                      capLevelToPlayerSize: true,
                      maxBufferLength: 60, // or 15 or 20 based on tests
                      maxMaxBufferLength: 30,
                      maxBufferSize: 60 * 1000 * 1000,
                      maxBufferHole: 2.5,
                      highBufferWatchdogPeriod: 10,
                      stretchShortVideoTrack: true,
                      maxFragLookUpTolerance: 2.5,
                      enableWorker: true,
                      lowLatencyMode: true,
                      backBufferLength: 90,
                      progressive: true,
                      startLevel: -1,
                      startPosition: -1,
                      testBandwidth: true,
                      liveDurationInfinity: false,
                      fragLoadingTimeOut: 20000,
                      manifestLoadingMaxRetry: 10,
                      levelLoadingMaxRetry: 10,
                      appendErrorMaxRetry: 10,
                      preferManagedMediaSource: true,
                      enableSoftwareAES: true,
                      frontBufferFlushThreshold: Infinity,
                      autoStartLoad: true,
                    },
                  },
                }}
                onError={(...args) => {
                  try {
                    var error = JSON.stringify(args[0]);
                    if (error === '"hlsError"') {
                      error = `${error}: { reason:${JSON.stringify(
                        args[1].reason
                      )}, details:${JSON.stringify(
                        args[1].details
                      )}, type:${JSON.stringify(
                        args[1].type
                      )}, fatal:${JSON.stringify(
                        args[1].fatal
                      )}, url:${JSON.stringify(args[1].url)}}`;
                    }
                    console.log(`There is a error with the video: ${error}`);
                  } catch {
                    console.log(
                      `There is a error with the video: ${JSON.stringify(args)}`
                    );
                  }
                }}
                onReady={(player) => {
                  // Set the currentTime to 0 before starting playback
                  player.seekTo(1, "seconds");
                }}
              />
              <input type="hidden" value={currVidUrl ?? ""} />
            </div>

            {/* React Player Info and Controls */}
            <div className="flex flex-col gap-2 p-4">
              {/* Video Start and End Time */}
              <div className="flex justify-between">
                <div className="flex flex-col text-left text-neutral-800 dark:text-neutral-300">
                  <div className="font-semibold">Start Time</div>
                  <div>
                    {`${moment(new Date(selectedDateRef.current)).format(
                      "ddd, MMM DD, YYYY"
                    )}  ${valueLabelFormat(timeRange[0])}`}
                  </div>
                </div>

                <div className="flex flex-col text-right text-neutral-800 dark:text-neutral-300">
                  <div className="font-semibold">End Time</div>
                  <div>
                    {`${moment(new Date(selectedDateRef.current)).format(
                      "ddd, MMM DD, YYYY"
                    )}  ${valueLabelFormat(timeRange[1])}`}
                  </div>
                </div>
              </div>

              {/* Video Controls */}
              <div className="flex flex-col border-2 border-guardis-300 bg-guardis-25 text-neutral-500 px-1">
                <TimeLine
                  handleCurentTimeChange={handleTimeChange}
                  handleSelectedEven={handleSelectedEven}
                  ref={timelineRef}
                />

                <div className="flex">
                  <div className="flex-1 p-1 text-left text-sm">
                    <h6>
                      <strong>Total Time: </strong>
                      {moment(
                        new Date(
                          0,
                          0,
                          0,
                          0,
                          0,
                          (timeRange[1] - timeRange[0]) * 60
                        )
                      ).format("HH:mm:ss", { trim: false })}
                    </h6>
                  </div>
                  <div className="inline-flex ">
                    <IconButton
                      title="Go to previus camera"
                      color="inherit"
                      onClick={() => changeCamera("prev")}
                    >
                      <SkipPreviousIcon fontSize="mediun"></SkipPreviousIcon>
                    </IconButton>
                    &nbsp;
                    <IconButton
                      title="Go back 10 seconds"
                      color="inherit"
                      onClick={() => setVideoSpeed(-10)}
                    >
                      <Replay10Icon fontSize="mediun"></Replay10Icon>
                    </IconButton>
                    &nbsp;
                    {playbackRate === 1 ? (
                      <IconButton
                        title="Play video in slow motion"
                        color="inherit"
                        onClick={() => {
                          setPlay(true);
                          setPlaybackRate(0.4);
                        }}
                      >
                        <SlowMotionVideoRoundedIcon fontSize="mediun"></SlowMotionVideoRoundedIcon>
                      </IconButton>
                    ) : (
                      <IconButton
                        title="Play video normal"
                        color="inherit"
                        onClick={() => {
                          setPlay(true);
                          setPlaybackRate(1);
                        }}
                      >
                        <PlayCircleOutlineIcon fontSize="mediun"></PlayCircleOutlineIcon>
                      </IconButton>
                    )}
                    &nbsp;
                    {!play ? (
                      <IconButton
                        title="Play video"
                        color="inherit"
                        onClick={() => {
                          setPlay(true);
                          setPlaybackRate(1);
                        }}
                      >
                        <PlayCircleRoundedIcon fontSize="mediun"></PlayCircleRoundedIcon>
                      </IconButton>
                    ) : (
                      <IconButton
                        title="Pause video"
                        color="inherit"
                        onClick={() => {
                          setPlay(false);
                          setPlaybackRate(1);
                        }}
                      >
                        <PauseCircleRounded fontSize="mediun"></PauseCircleRounded>
                      </IconButton>
                    )}
                    &nbsp;
                    <IconButton
                      title="Go forward 10 seconds"
                      color="inherit"
                      onClick={() => setVideoSpeed(10)}
                    >
                      <Forward10Icon fontSize="mediun"></Forward10Icon>
                    </IconButton>
                    &nbsp;
                    <IconButton
                      title="Go to next camera"
                      color="inherit"
                      onClick={() => changeCamera("next")}
                    >
                      <SkipNextIcon fontSize="mediun"></SkipNextIcon>
                    </IconButton>
                  </div>
                  <div className="flex-1 p-1 text-right text-sm">
                    <h6>
                      {moment(new Date(0, 0, 0, 0, 0, currentTime)).format(
                        "HH:mm:ss",
                        {
                          trim: false,
                        }
                      )}
                      /
                      {moment(
                        new Date(0, 0, 0, 0, 0, timeRange[1] * 60)
                      ).format("HH:mm:ss", { trim: false })}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-96">
        <VideoList
          cameraList={cameraList}
          createVideoUrl={createVideoUrl}
          setMainVideo={setMainVideo}
          setCurrCamera={setCurrCamera}
        />
      </div>

      <NotificationModal ref={notificationModalControl} />
    </div>
  );
});

export default Playback;
