let notificationAPI = null;

export const initializeNotificationService = (notification) => {
  notificationAPI = notification;
};

export const notificationService = {
  success: (config) =>
    notificationAPI?.success({
      className: "notification-base notification-success",
      ...config,
    }),
  error: (config) =>
    notificationAPI?.error({
      className: "notification-base notification-error",
      ...config,
    }),
  warning: (config) =>
    notificationAPI?.warning({
      className: "notification-base notification-warning",
      ...config,
    }),
  info: (config) =>
    notificationAPI?.info({
      className: "notification-base notification-info",
      ...config,
    }),
};
