import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import FunnelGraph from "funnel-graph-js";
import { Select, Segmented, Button } from "antd";
import moment from "moment";

import analyticsApi from "../../api/analytics";

import { notificationService } from "../../services/notificationService";

export default function SalesFunnel({ selectedDate = new Date() }) {
  const navigate = useNavigate();
  const cameraList = useSelector((state) => state.camera.cameraList);

  const [cameras, setCameras] = useState([]);

  const [showByCamerasFunnelGraph, setShowByCamerasFunnelGraph] =
    useState(false);
  const [conversionRates, setConversionRates] = useState({
    previousWeek: 0,
    currentWeek: 0,
  });

  const graphRef = useRef(null);
  const firstTime = useRef(true);

  useEffect(() => {
    const setHour = () => {
      selectedDate.setHours(23, 59, 59);
    };

    const createChart = async () => {
      const data = await generateSalesFunnelChartByWeeksModel();
      const options = getFunnelGraphOptions(data);

      const funnelContainerHtml = document.getElementById("funnelContainer");
      const funnel = new FunnelGraph(options);
      graphRef.current = funnel;

      if (graphRef.current && funnelContainerHtml) {
        graphRef.current.draw();
      }
    };

    if (firstTime.current) {
      setHour();
      createChart();
      firstTime.current = false;
    }

    setTimeout(() => {
      var duplicateLabels = document.getElementsByClassName(
        "svg-funnel-js__subLabels"
      );

      if (duplicateLabels.length > 1) {
        duplicateLabels[1].classList.add(
          "svg-funnel-js__subLabels_hiden_duplpicate"
        );
      }
    }, 600);
  });

  const generateSalesFunnelChartByWeeksModel = async () => {
    let endDate = new Date(selectedDate);
    let startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 7);
    startDate.setHours(0, 0, 0, 0);

    const currentWeekCameraActions =
      await analyticsApi.getfunnelSaleChartDataForAllCameras(
        startDate,
        endDate
      );

    let previousEndDate = new Date(startDate);
    previousEndDate.setDate(previousEndDate.getDate() - 1);
    previousEndDate.setHours(23, 59, 59, 59);

    const previousStartDate = new Date(previousEndDate);
    previousStartDate.setDate(previousStartDate.getDate() - 7);
    previousStartDate.setHours(0, 0, 0, 0);

    const previousWeekCameraActions =
      await analyticsApi.getfunnelSaleChartDataForAllCameras(
        previousStartDate,
        previousEndDate
      );

    if (!currentWeekCameraActions.status || !previousWeekCameraActions.status) {
      const errorMessage = !currentWeekCameraActions.status
        ? currentWeekCameraActions.error
        : previousWeekCameraActions.error;
      notificationService.error({
        message: "Error with getting Sales Funnel data",
        description: errorMessage,
      });
    }

    const data = generateChartModelForAllCameras(
      currentWeekCameraActions.result,
      previousWeekCameraActions.result
    );

    return data;
  };

  const generateChartByCamerasModel = (cameras) => {
    let cameraNames = [];
    let colors = [];
    let opportunityList = [];
    let engagedList = [];
    let salesList = [];

    cameras?.forEach((cameraAction) => {
      const camera = cameraList.filter(
        (camera) => cameraAction.camera_mac === camera.mac
      )[0];

      if (camera) {
        cameraNames.push(camera.name);

        colors.push(getRandomColor());
        opportunityList.push(cameraAction.opportunity);
        engagedList.push(cameraAction.engage);
        salesList.push(cameraAction.sales);
      }
    });

    let values = [];
    values.push(opportunityList);
    values.push(engagedList);
    values.push(salesList);

    const data = {
      labels: ["Opportunities", "Engagement", "Sales"],
      subLabels: cameraNames,
      colors: colors,
      values: values,
    };

    return data;
  };

  const getFunnelGraphOptions = (data) => {
    return {
      container: "#funnelContainer",
      gradientDirection: "horizontal",
      data,
      displayPercent: true,
      direction: "horizontal",
      height: 200,
      subLabelValue: "raw",
    };
  };

  const getBasicFilterModel = () => {
    let model = {};

    const endDate = new Date(selectedDate);
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 7);

    model.intent = "analytical";
    model.action_type_ids = [];
    model.start_time = moment(startDate).format("YYYY-MM-DD 00:00:00");
    model.end_time = moment(endDate).format("YYYY-MM-DD 23:59:59");

    return model;
  };

  const redirectToEventsPage = (event, category) => {
    event.preventDefault();
    let model = getBasicFilterModel();

    switch (category) {
      case "Opportunity":
        model.action_type_ids = [14, 7, 9];
        break;
      case "Engaged":
        model.action_type_ids = [2];
        break;
      case "Sales":
        model.action_type_ids = [10];
        break;
      default:
        model.action_type_ids = [9, 2, 10, 14, 7];
        break;
    }

    navigate("/events", {
      state: {
        filterModel: model,
      },
    });
  };

  const getRandomColor = () => {
    // Generate random values for red, green, and blue components
    const red = Math.floor(Math.random() * 256)
      .toString(16)
      .padStart(2, "0"); // Random integer between 0 and 255
    const green = Math.floor(Math.random() * 256)
      .toString(16)
      .padStart(2, "0");
    const blue = Math.floor(Math.random() * 256)
      .toString(16)
      .padStart(2, "0");

    // Construct the color string in hexadecimal format
    const color = `#${red}${green}${blue}`;

    return [color];
  };

  const generateChartModelForAllCameras = (
    currentWeekCameraActios,
    previousWeekCameraActios
  ) => {
    let values = [];
    values.push([
      currentWeekCameraActios.opportunity,
      previousWeekCameraActios.opportunity,
    ]);
    values.push([
      currentWeekCameraActios.engaged,
      previousWeekCameraActios.engaged,
    ]);
    values.push([
      currentWeekCameraActios.sales,
      previousWeekCameraActios.sales,
    ]);

    const data = {
      labels: ["Opportunities", "Engagement", "Sales"],
      subLabels: ["Current Week", "Previous Week"],
      colors: ["#0b4f24", "#389b72"],
      values: values,
    };

    const previousWeek = (
      (previousWeekCameraActios.sales / previousWeekCameraActios.opportunity) *
      100
    ).toFixed(2);
    const currentWeek = (
      (currentWeekCameraActios.sales / currentWeekCameraActios.opportunity) *
      100
    ).toFixed(2);

    setConversionRates({ previousWeek, currentWeek });

    return data;
  };

  const updateChartByCameras = async (dataModel) => {
    let endDate = new Date(selectedDate);
    let startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 7);
    startDate.setHours(0, 0, 0, 0);

    const cameraActions = await analyticsApi.getfunnelSaleChartDataByCameras(
      dataModel.cameraMacs,
      startDate,
      endDate
    );

    if (cameraActions.status) {
      const data = generateChartByCamerasModel(cameraActions.result);
      const options = getFunnelGraphOptions(data);

      graphRef.current.update(options);

      setTimeout(() => {
        graphRef.current.update(options);
      }, 6);
    } else {
      notificationService.error({
        message: "Error with getting Sales Funnel data",
        description: "Can't retrieve funnel sales data by cameras",
      });
    }
  };

  const updateChartByWeeks = async () => {
    const data = await generateSalesFunnelChartByWeeksModel();
    const options = getFunnelGraphOptions(data);

    graphRef.current.update(options);

    setTimeout(() => {
      graphRef.current.update(options);
    }, 6);
  };

  const handleNotificationTypeSelectChange = (cameraIDs) => {
    const selectedCameras = cameraList.filter((camera) =>
      cameraIDs.includes(camera.uuid)
    );

    setCameras(selectedCameras);
    updateChartByCameras({
      cameraNames: selectedCameras.map((camera) => camera.name),
      cameraMacs: selectedCameras.map((camera) => camera.mac),
    });
  };

  const setChartByCameras = () => {
    updateChartByCameras({ cameraNames: [], cameraMacs: [] });
    setShowByCamerasFunnelGraph(true);
  };

  const setChartByWeek = () => {
    updateChartByWeeks([]);
    setShowByCamerasFunnelGraph(false);
  };

  return (
    <div className="w-full card flex flex-col gap-5 pt-4 pb-8">
      <div className="flex flex-col gap-6 mx-6 text-center">
        <h2>Sales Analytics</h2>
        <Segmented
          value={!showByCamerasFunnelGraph ? "Weekly Comparison" : "By Cameras"}
          options={["Weekly Comparison", "By Cameras"]}
          onChange={(value) => {
            if (value === "Weekly Comparison") {
              setCameras([]);
              setChartByWeek();
            } else {
              setChartByCameras();
            }
          }}
          block
          size="large"
        />
        {showByCamerasFunnelGraph && (
          <Select
            className="w-full"
            placeholder="Select a Camera"
            value={cameras.map((camera) => camera.uuid)}
            options={cameraList}
            fieldNames={{ label: "name", value: "uuid" }}
            onChange={(selectCamera) => {
              handleNotificationTypeSelectChange(selectCamera);
            }}
            mode="multiple"
            showSearch
            variant="filled"
          />
        )}
      </div>

      <div id="funnelContainer"></div>

      {!showByCamerasFunnelGraph && (
        <div>
          <div className="flex flex-col justify-center text-center">
            <h4>Overall Sales Conversion</h4>

            <div className="flex justify-center gap-5 text-sm">
              <div className="flex justify-center gap-1 text-guardis-800 dark:text-guardis-600">
                <p>Previous Week |</p>
                <p className="font-semibold">{conversionRates.previousWeek}%</p>
              </div>

              <div className="flex justify-center gap-1 text-guardis-600 dark:text-guardis-400">
                <p>Current Week |</p>
                <p className="font-semibold">{conversionRates.currentWeek}%</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center gap-2">
        <Button size="small" onClick={(e) => redirectToEventsPage(e, "")}>
          All
        </Button>
        <Button
          size="small"
          onClick={(e) => redirectToEventsPage(e, "Opportunity")}
        >
          Opportunities
        </Button>
        <Button
          size="small"
          onClick={(e) => redirectToEventsPage(e, "Engaged")}
        >
          Engagement
        </Button>
        <Button size="small" onClick={(e) => redirectToEventsPage(e, "Sales")}>
          Sales
        </Button>
      </div>
    </div>
  );
}
