import { cameraAction } from "./camera";
import cameraApi from "../../api/camera";
import { notificationAction } from "../notification/notification";

import { notificationService } from "../../services/notificationService";

export const getCameras = () => {
  return async (dispatch) => {
    try {
      let response = await cameraApi.getCameras();

      if (response.status) {
        dispatch(cameraAction.replaceCameras(response.camera_list));
        dispatch(notificationAction.setCameraList(response.camera_list));
      } else {
        response = await cameraApi.getCameras();

        if (response.status) {
          dispatch(cameraAction.replaceCameras(response.camera_list));
          dispatch(notificationAction.setCameraList(response.camera_list));
        } else {
          notificationService.error({
            message: "Error getting Camera data",
            description: response.error,
          });
        }
      }
      if (response.status) {
        let brokenCameras = [];

        response.camera_list.forEach((camera) => {
          if (!camera.ishealthy) {
            brokenCameras.push(camera.name);
          }
        });

        if (brokenCameras.length > 0) {
          const oneCamera = `camera: ${brokenCameras[0]}`;
          const multipleCameras = `cameras: ${brokenCameras
            .map((camera, i) => {
              if (i === brokenCameras.length - 1) {
                return `and ${camera}`;
              }
              return `${camera}, `;
            })
            .join("")}`;

          let alertMessage = (
            <>
              An error occurred with{" "}
              {brokenCameras.length === 1 ? oneCamera : multipleCameras}. See
              the <a href="#/cameraHelp">camera troubleshooting guide</a> for
              information on how to resolve the errors.
            </>
          );

          notificationService.warning({
            message: "Error with Cameras",
            description: alertMessage,
            duration: 0,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCamera = (originalCameraData, modifiedCameraData) => {
  let updatedCameraResponse;

  return async (dispatch) => {
    try {
      // Update camera except action types
      const cameraData = { ...modifiedCameraData };
      cameraData.action_types = originalCameraData.action_types;

      updatedCameraResponse = await cameraApi.updateCamera(cameraData);
    } catch (error) {
      notificationService.error({
        message: "Error updating camera",
        description: error,
      });
    }

    // Find newly added action types
    const newActionTypes = modifiedCameraData.action_types.filter(
      (modifiedActionType) =>
        !originalCameraData.action_types.find(
          (originalActionType) =>
            originalActionType.id === modifiedActionType.id
        )
    );

    // Find action types with updated intent
    const updatedActionTypes = modifiedCameraData.action_types.filter(
      (modifiedActionType) => {
        const originalActionType = originalCameraData.action_types.find(
          (actionType) => actionType.id === modifiedActionType.id
        );

        return (
          originalActionType &&
          originalActionType.intent !== modifiedActionType.intent
        );
      }
    );

    // Find deleted action types
    const deletedActionTypes = originalCameraData.action_types.filter(
      (originalActionType) =>
        !modifiedCameraData.action_types.find(
          (modifiedActionType) =>
            modifiedActionType.id === originalActionType.id
        )
    );

    // Add newly added action types
    if (newActionTypes.length > 0) {
      try {
        await cameraApi.addCameraActions(
          modifiedCameraData.mac,
          newActionTypes
        );
      } catch (error) {
        notificationService.error({
          message: "Error adding new action types to camera",
          description: error,
        });
      }
    }

    // Update action types with updated intent
    if (updatedActionTypes.length > 0) {
      try {
        await cameraApi.updateCameraActions(
          modifiedCameraData.mac,
          updatedActionTypes
        );
      } catch (error) {
        notificationService.error({
          message: "Error adding intents to camera action types",
          description: error,
        });
      }
    }

    // Delete action types
    if (deletedActionTypes.length > 0) {
      try {
        await cameraApi.deleteCameraActions(
          modifiedCameraData.mac,
          deletedActionTypes
        );
      } catch (error) {
        notificationService.error({
          message: "Error adding deleting camera action types",
          description: error,
        });
      }
    }

    updatedCameraResponse.result.action_types = modifiedCameraData.action_types;
    dispatch(cameraAction.updateCamera(updatedCameraResponse.result));
    return updatedCameraResponse.status;
  };
};

export const removeCamera = (camera) => {
  return async (dispatch) => {
    try {
      const response = await cameraApi.removeCamera(camera);
      if (response.status) {
        dispatch(cameraAction.removeCameras(camera));
      } else {
        notificationService.error({
          message: "Error removing camera",
          description: response.error,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCameraHealthy = (mac, ishealthy) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setCameraHealthy({ mac, ishealthy }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addCamera = (camera, isFromScanList = false) => {
  return async (dispatch) => {
    try {
      let result = false;

      const response = await cameraApi.addCamera(camera);

      if (isFromScanList) {
        dispatch(
          cameraAction.setScanCameraStatus({
            mac: camera.mac,
            status: response.status,
          })
        );
      }
      if (response.status) {
        dispatch(cameraAction.addCamera(response.result));
        if (!isFromScanList) {
          cameraApi.restartServices();
        }
        result = true;
      } else {
        notificationService.error({
          message: "Error adding camera",
          description: response.error,
        });
      }
      return result;
    } catch (err) {
      console.log(err);
    }
  };
};

export const scanNetwork = () => {
  return async (dispatch) => {
    try {
      const response = await cameraApi.scanNetwork();

      if (response.status) {
        dispatch(cameraAction.setScanCamera(response.scanCamera_list));
      } else {
        notificationService.error({
          message: "Error with scanning network for cameras",
          description: response.error,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetScanCamera = () => {
  return async (dispatch) => {
    try {
      dispatch(cameraAction.setScanCamera(null));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setScanCameraUsername = (mac, username) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setScanCameraUsername({ mac, username }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSelectScanCamera = (index, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setSelectScanCamera({ index, value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSelectScanCameraAll = (value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setSelectScanCameraAll({ value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeScanCamera = (mac) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.removeScanCamera(mac));
    } catch (err) {
      console.log(err);
    }
  };
};

export const restartServices = () => {
  return async (dispatch) => {
    try {
      await cameraApi.restartServices();
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInScanCamera = (index, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setLoadingInScanCamera({ index, value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInCameraByMac = (mac, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setLoadingInCameraByMac({ mac, value }));
    } catch (err) {
      console.log(err);
    }
  };
};
