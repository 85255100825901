import { useEffect, useState } from "react";

import { Skeleton } from "antd";

import salesAPI from "../../api/sales";
import SalesVideoPlayer from "../SalesVideoPlayer";

export default function SalesVideoClips({
  salesType = "",
  cameras = [],
  timestamp = null,
}) {
  const [playbackData, setPlaybackData] = useState([]);
  const [loadingPlaybackData, setLoadingPlaybackData] = useState(false);

  useEffect(() => {
    const fetchSalesFootage = async () => {
      if (cameras.length === 0 || !timestamp) {
        setPlaybackData([]);
        return;
      }

      setLoadingPlaybackData(true);

      try {
        const checkVideoClip = async (camera, offsetStart, offsetEnd) => {
          const startTime = new Date(timestamp);
          const endTime = new Date(timestamp);

          startTime.setSeconds(startTime.getSeconds() + offsetStart);
          endTime.setSeconds(endTime.getSeconds() + offsetEnd);

          const response = await salesAPI.checkSalesFootage(
            camera,
            startTime,
            endTime
          );
          return response.data;
        };

        const data = await Promise.all(
          cameras.map(async (camera) => {
            const [beforeVideoClip, duringVideoClip, afterVideoClip] =
              await Promise.all([
                checkVideoClip(camera, -50, -20), // 30-second clip before timestamp
                checkVideoClip(camera, -20, 20), // 40-second clip with timestamp
                checkVideoClip(camera, 20, 50), // 30-second clip after timestamp
              ]);

            return {
              ...camera,
              beforeVideoClip,
              duringVideoClip,
              afterVideoClip,
            };
          })
        );

        setPlaybackData(data);
      } catch (error) {
        setPlaybackData([]);
      } finally {
        setLoadingPlaybackData(false);
      }
    };

    fetchSalesFootage();
  }, [cameras, timestamp]);

  return (
    <div className="h-full flex flex-col gap-8 px-16 pt-12 pb-16 overflow-y-scroll snap-y">
      {loadingPlaybackData &&
        playbackData.length === 0 &&
        cameras.map((camera) => {
          return (
            <Skeleton.Node
              key={`loading-${camera.name}`}
              className="w-full h-56"
              active={true}
              children={<></>}
            />
          );
        })}

      {playbackData &&
        playbackData.length > 0 &&
        playbackData.map((camera) => {
          return <SalesVideoPlayer salesType={salesType} camera={camera} />;
        })}
    </div>
  );
}
