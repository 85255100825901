import notificationApi from "../../api/notification";
import { lookupAction } from "./lookup";

import { notificationService } from "../../services/notificationService";

export const getNotificationTypes = () => {
  return async (dispatch) => {
    try {
      const respose = await notificationApi.getNotificationTypes();
      if (respose.status) {
        dispatch(lookupAction.setActionTypes(respose.actionTypes));
      } else {
        const respose = await notificationApi.getNotificationTypes();
        if (respose.status) {
          dispatch(lookupAction.setActionTypes(respose.actionTypes));
        } else {
          notificationService.error({
            message: "Error retrieving notification types",
            description: Response.error,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};
