import authService from "../services/authService";
import axios from "../extension/axios";

const registersAPI = {
  getRegister: async (registerID) => {
    let response;

    try {
      response = await axios.get(
        `${authService.getUnitUrl()}registers/register/${registerID}`
      );
    } catch (error) {
      let message = `There was an error retrieving register with ID ${registerID}.`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  getAllRegisters: async () => {
    let response;

    try {
      response = await axios.get(`${authService.getUnitUrl()}registers`);
    } catch (error) {
      let message = `There was an error retrieving all registers`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  createRegister: async (registerID, registerName) => {
    const requestData = { id: registerID, name: registerName };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}registers/register/${registerID}`,
        requestData
      );
    } catch (error) {
      let message = `There was an error creating a register with ID ${registerID} and name ${registerName}.`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  updateRegister: async (registerID, registerName) => {
    const requestData = { name: registerName };

    let response;

    try {
      response = await axios.put(
        `${authService.getUnitUrl()}registers/register/${registerID}`,
        requestData
      );
    } catch (error) {
      let message = `There was an error updating a register with ID ${registerID}.`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  deleteRegister: async (registerID) => {
    let response;

    try {
      response = await axios.delete(
        `${authService.getUnitUrl()}registers/register/${registerID}`
      );
    } catch (error) {
      let message = `There was an error deleting a register with ID ${registerID}.`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  linkCameraToRegister: async (registerID, cameraMAC) => {
    const requestData = { camera_mac: cameraMAC };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}registers/register_camera/${registerID}`,
        requestData
      );
    } catch (error) {
      let message = `There was an error linking register with ID ${registerID} and camera with MAC ${cameraMAC}.`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  unlinkCameraToRegister: async (registerID, cameraMAC) => {
    const requestData = { camera_mac: cameraMAC };

    let response;

    try {
      response = await axios.delete(
        `${authService.getUnitUrl()}registers/register_camera/${registerID}`,
        requestData
      );
    } catch (error) {
      let message = `There was an error unlinking register with ID ${registerID} and camera with MAC ${cameraMAC}.`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  getCamerasLinkedToRegisters: async (registerID, currentlyLinked = false) => {
    const requestData = { currently_linked: currentlyLinked };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}registers/register_cameras/${registerID}`,
        requestData
      );
    } catch (error) {
      let message = `There was an error retrieving linked cameras to register with ID ${registerID}.`;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
};

export default registersAPI;
