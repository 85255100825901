import moment from "moment";

// HELPER FUNCTIONS

// helper functions for translateSalesFilters(filters)
const isValidDateObject = (dateObject) => dateObject.constructor === Date;
const isValidDateRange = (dateRange) =>
  Array.isArray(dateRange) &&
  dateRange.length === 2 &&
  isValidDateObject(dateRange[0]) &&
  isValidDateObject(dateRange[1]);

const isNumericalString = (str) => {
  return /^-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(str);
};

// UTILITY FUNCTION FOR checkSalesFootage

export const isValidM3U8Playlist = (playlist) => {
  const lines = playlist.trim().split("\n");

  if (lines.length <= 3) {
    return false;
  }

  const hasM3UHeader = playlist.includes("#EXTM3U");
  const hasVersion = playlist.includes("#EXT-X-VERSION");
  const hasSegments = playlist.includes("#EXTINF") || playlist.includes(".ts");

  return hasM3UHeader && hasVersion && hasSegments;
};

// UTILITY FUNCTION FOR getFilteredSalesTransactions API CALL FUNCTION
export const translateSalesFilters = (filters) => {
  let requestBody = {};

  let start_time = null;
  let end_time = null;
  let register_ids = [];
  let payment_methods = [];
  let total = [];
  let items_count = [];

  filters.forEach((filter) => {
    switch (filter.key) {
      case "timestamp":
        switch (filter.operator) {
          case "on":
            if (isValidDateObject(filter.value)) {
              start_time = new Date(filter.value);
              end_time = new Date(filter.value);

              start_time.setHours(0, 0, 0, 0);
              end_time.setHours(23, 59, 59, 59);
            }
            break;
          case "before":
            if (isValidDateObject(filter.value)) {
              end_time = new Date(filter.value);
              end_time.setHours(0, 0, 0, 0);
            }
            break;
          case "after":
            if (isValidDateObject(filter.value)) {
              start_time = new Date(filter.value);
              start_time.setHours(23, 59, 59, 5);
            }
            break;
          case "between":
            if (isValidDateRange(filter.value)) {
              start_time = new Date(filter.value[0]);
              end_time = new Date(filter.value[1]);

              start_time.setHours(0, 0, 0, 0);
              end_time.setHours(23, 59, 59, 59);
            }
            break;
          default:
            break;
        }
        break;

      case "register":
        if (Array.isArray(filter.value)) {
          filter.value.forEach((value) => register_ids.push(value));
        } else if (typeof filter.value === "string") {
          register_ids.push(filter.value);
        }
        break;

      case "paymentMethod":
        if (Array.isArray(filter.value)) {
          filter.value.forEach((value) => payment_methods.push(value));
        } else if (typeof filter.value === "string") {
          payment_methods.push(filter.value);
        }
        break;

      case "total":
        switch (filter.operator) {
          case "eq":
          case "neq":
          case "gt":
          case "lt":
            if (isNumericalString(filter.value)) {
              const newComparisonFilter = {};
              newComparisonFilter[filter.operator] = filter.value;

              total.push(newComparisonFilter);
            }
            break;
          case "gte":
            if (isNumericalString(filter.value)) {
              const greaterThanFilter = {};
              greaterThanFilter["gt"] = filter.value;

              const equalToFilter = {};
              equalToFilter["eq"] = filter.value;

              total.push(greaterThanFilter);
              total.push(equalToFilter);
            }
            break;
          case "lte":
            if (isNumericalString(filter.value)) {
              const lessThanFilter = {};
              lessThanFilter["lt"] = filter.value;

              const equalToFilter = {};
              equalToFilter["eq"] = filter.value;

              total.push(lessThanFilter);
              total.push(equalToFilter);
            }
            break;
          default:
            break;
        }
        break;
      case "numberOfItems":
        switch (filter.operator) {
          case "eq":
          case "neq":
          case "gt":
          case "lt":
            if (Number.isInteger(filter.value)) {
              const newComparisonFilter = {};
              newComparisonFilter[filter.operator] = filter.value;

              items_count.push(newComparisonFilter);
            }
            break;
          case "gte":
            if (Number.isInteger(filter.value)) {
              const greaterThanFilter = {};
              greaterThanFilter["gt"] = filter.value;

              const equalToFilter = {};
              equalToFilter["eq"] = filter.value;

              total.push(greaterThanFilter);
              total.push(equalToFilter);
            }
            break;
          case "lte":
            if (Number.isInteger(filter.value)) {
              const lessThanFilter = {};
              lessThanFilter["lt"] = filter.value;

              const equalToFilter = {};
              equalToFilter["eq"] = filter.value;

              total.push(lessThanFilter);
              total.push(equalToFilter);
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  });

  // add filters to request body object

  if (start_time) {
    requestBody["start_time"] = moment(start_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }

  if (end_time) {
    requestBody["end_time"] = moment(end_time).format("YYYY-MM-DD HH:mm:ss");
  }

  if (register_ids.length > 0) {
    requestBody["register_ids"] = register_ids;
  }

  if (payment_methods.length > 0) {
    requestBody["payment_methods"] = payment_methods;
  }

  if (total.length > 0) {
    requestBody["total"] = total;
  }

  if (items_count.length > 0) {
    requestBody["items_coumt"] = items_count;
  }

  return requestBody;
};
