export const retryRequest = async (request, maxRetries = 1) => {
  let lastError;

  for (let attempt = 0; attempt <= maxRetries; attempt++) {
    try {
      const response = await request();

      if (response.status) {
        return response;
      }

      lastError = response.error;
    } catch (error) {
      lastError = error;
    }
  }

  return { status: false, error: lastError };
};
