import { unitAction } from "./unit";
import unitApi from "../../api/unit";
import { notificationService } from "../../services/notificationService";

export const getUnit = () => {
  return async (dispatch) => {
    try {
      const response = await unitApi.getUnit();
      if (response.status) {
        dispatch(unitAction.setUnit(response.result));
      } else {
        const response = await unitApi.getUnit();
        if (response.status) {
          dispatch(unitAction.setUnit(response.result));
        } else {
          notificationService.error({
            message: "Error connecting to unit",
            description: response.error,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateUnitSettings = (settings, isNew) => {
  return async (dispatch) => {
    try {
      let response;

      if (isNew) {
        response = await unitApi.setUnitLocation(settings);
      } else {
        response = await unitApi.updateUnitSettings(settings);
      }

      if (response.status) {
        dispatch(unitAction.setUnit(response.result));
      } else {
        notificationService.error({
          message: "Error updating unit settings",
          description: response.error,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setUnitInEditMode = (value) => {
  return (dispatch) => {
    try {
      dispatch(unitAction.setUnitInEditMode(value));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInUnit = (value) => {
  return (dispatch) => {
    try {
      dispatch(unitAction.setLoadingInUnit(value));
    } catch (err) {
      console.log(err);
    }
  };
};
