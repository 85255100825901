import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ReactPlayer from "react-player";
import moment from "moment";
import { Button, Drawer, Empty, Input, Table, Tabs, Tag } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import notificationApi from "../api/notification";
import authService from "../services/authService";

export default function Bookmarks() {
  const intentList = useSelector((state) => state.lookup.intentList);
  const cameraList = useSelector((state) => state.camera.cameraList).map(
    (camera) => ({
      camera_id: camera.mac,
      camera_name: camera.name,
    })
  );
  const [bookmarks, setBookmarks] = useState({});
  const [getBookmarks, setGetBookmarks] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState(
    cameraList[0]?.camera_id
  );

  const [openVideoPlayerModal, setOpenVideoPlayerModal] = useState(false);
  const [currentVideoClip, setCurrentVideoClip] = useState(null);
  const [currentVideoClipURL, setCurrentVideoClipURL] = useState(null);

  useEffect(() => {
    const fetchBookmarks = async () => {
      if (getBookmarks) {
        setGetBookmarks(false);

        const response = await notificationApi.getBookmarksByFilter();

        if (response.status) {
          let filteredBookmarks = {};

          cameraList.forEach((camera) => {
            filteredBookmarks[camera.camera_id] = [];
          });

          response.bookmarks.forEach((bookmark) => {
            let camera_id = bookmark.camera_id;

            if (camera_id in filteredBookmarks) {
              filteredBookmarks[camera_id].push(bookmark);
            }
          });

          setBookmarks(filteredBookmarks);
        }
      }
    };

    fetchBookmarks();
  }, [cameraList, getBookmarks]);

  useEffect(() => {
    if (currentVideoClip) {
      const clip_id = currentVideoClip.clip_id;

      const url = `${authService.getUnitUrl()}video_captions/get_video/${clip_id}`;
      setCurrentVideoClipURL(url);
    }
  }, [currentVideoClip]);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      width: 100,
      render: (value) => (
        <div className="flex flex-wrap gap-1 items-center">
          {moment(value, "MM/DD/YYYY H:mm:ss").format("MMMM Do YYYY")}
          <div className="dot"></div>
          {moment(value, "MM/DD/YYYY H:mm:ss").format("h:mm A")}
        </div>
      ),
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        moment(a.timestamp, "MM/DD/YYYY HH:mm:ss").valueOf() -
        moment(b.timestamp, "MM/DD/YYYY HH:mm:ss").valueOf(),
    },
    {
      title: "Actions Detected",
      dataIndex: "actionsDetected",
      key: "actionsDetected",
      width: 250,
      render: (_, bookmark) => (
        <div className="flex flex-wrap gap-1">
          {bookmark.actionEvents.map((action, i) => (
            <Tag
              className="me-0"
              key={`current_video_clip_actions-${i}`}
              style={{
                fontSize: 13,
                lineHeight: 2,
                borderColor: getIntentLabelColor(action.intent),
                color: getIntentLabelColor(action.intent),
              }}
            >
              {action.name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Intent",
      dataIndex: "intentDetected",
      key: "intentDetected",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Play Clip",
      key: "play",
      render: (_, record) => {
        return (
          <Button
            shape="circle"
            icon={<CaretRightOutlined />}
            onClick={() => {
              setCurrentVideoClip(record);
              setOpenVideoPlayerModal(true);
            }}
          />
        );
      },
    },
  ];

  const getIntentLabel = (value) => {
    const intent = intentList.find((option) => option.value === value);
    return intent ? intent.label : value;
  };

  const getIntentLabelColor = (value) => {
    const intent = intentList.find((option) => option.value === value);
    return intent ? intent.color : "#FFFFFF";
  };

  return (
    <div className="h-full w-full flex flex-col gap-10 px-6 py-8">
      <h1 className="page-header">Bookmarks</h1>

      <Tabs
        type="card"
        size="large"
        tabBarStyle={{ margin: 0, marginBottom: -1 }}
        items={cameraList.map((camera) => {
          const id = camera.camera_id;

          return {
            label: camera.camera_name,
            key: id,
            children: (
              <Table
                bordered
                className="tables"
                columns={columns}
                rowKey="clip_id"
                pagination={false}
                dataSource={bookmarks[id] ? bookmarks[id] : null}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={`No bookmarks found for ${
                        cameraList.find(
                          (camera) => camera.camera_id === selectedCamera
                        ).camera_name
                      }.`}
                    />
                  ),
                }}
              />
            ),
          };
        })}
        activeKey={selectedCamera}
        onChange={(camera) => setSelectedCamera(camera)}
      />

      <Drawer
        title="Play Bookmarked Video Clip"
        size="large"
        open={openVideoPlayerModal}
        onClose={() => {
          setOpenVideoPlayerModal(false);
          setCurrentVideoClip(null);
          setCurrentVideoClipURL(null);
        }}
      >
        <div className="flex flex-col gap-6 px-6">
          <ReactPlayer
            url={currentVideoClipURL}
            width="100%"
            height="auto"
            controls
          />

          <div className="flex justify-between">
            <div className="flex gap-4">
              <h2 className="font-semibold text-neutral-600 dark:text-neutral-100">
                {
                  cameraList.find((value) => value.camera_id === selectedCamera)
                    .camera_name
                }
              </h2>
              <div className="flex">
                {currentVideoClip?.intentDetected
                  ?.split(", ")
                  .map((item, index, array) => {
                    const label =
                      index < array.length - 1
                        ? `${getIntentLabel(item)},`
                        : getIntentLabel(item);
                    return (
                      <Tag
                        key={index}
                        color={getIntentLabelColor(item)}
                        style={{
                          fontSize: 14,
                          lineHeight: 2,
                        }}
                      >
                        {label}
                      </Tag>
                    );
                  })}
              </div>
            </div>

            <h2 className="font-medium text-neutral-400">
              {currentVideoClip?.timestamp}
            </h2>
          </div>

          <div className="flex flex-col gap-1 justify-between">
            <h3>Actions Detected</h3>
            <div>
              {currentVideoClip?.actionEvents.map((action, i) => (
                <Tag
                  key={`current_video_clip_actions-${i}`}
                  style={{
                    fontSize: 14,
                    lineHeight: 2,
                    borderColor: getIntentLabelColor(action.intent),
                    color: getIntentLabelColor(action.intent),
                  }}
                >
                  {action.name}
                </Tag>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <h3>Event Description</h3>
            <Input.TextArea
              className="rounded text-justify border border-green px-2 py-2 responsive-textarea"
              value={currentVideoClip?.description}
              readOnly={true}
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
}
