import SettingsIcon from "@mui/icons-material/Settings";
import VideocamIcon from "@mui/icons-material/Videocam";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { DollarOutlined } from "@ant-design/icons";

export const navData = [
  {
    id: 0,
    icon: <ShowChartIcon />,
    text: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    icon: <VideocamIcon />,
    text: "Live",
    link: "live",
  },
  {
    id: 4,
    icon: <PlayCircleOutlineIcon />,
    text: "Playback",
    link: "playback",
  },
  {
    id: 5,
    icon: <NotificationsIcon />,
    text: "Events",
    link: "events",
  },
  {
    id: 6,
    icon: <DollarOutlined />,
    text: "Sales",
    link: "sales",
  },
  {
    id: 7,
    icon: <PersonalVideoIcon />,
    text: "Monitoring",
    link: "*",
  },
  {
    id: 8,
    icon: <BookmarksIcon />,
    text: "Bookmarks",
    link: "bookmarks",
  },
  {
    id: 9,
    icon: <SettingsIcon />,
    text: "Setup",
    link: "setup",
  },
  {
    id: 10,
    icon: <LogoutIcon />,
    text: "Sign Out",
    link: "sign-out",
  },
];
