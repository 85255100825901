import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  Fragment,
  useRef,
} from "react";
import Modal from "react-modal";
import { addCamera } from "../../store/camera/cameraAction";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlined from "@mui/icons-material/InfoRounded";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { Dialog, Transition } from "@headlessui/react";

import { messageService } from "../../services/messageService";

const AddCameraModal = forwardRef((props, ref) => {
  const dialogRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const cameraRoles = useSelector((state) => state.lookup.cameraRoles);
  const [popoverMessage, setPopoverMessage] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpenAddCameraModal, setIsOpenAddCameraModal] = useState(false);
  const [deviceFormData, setDeviceFormData] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    Modal.setAppElement("body");
  });

  useImperativeHandle(ref, () => ({
    openDeviceModal,
  }));

  const openDeviceModal = () => {
    const camera = {
      name: "",
      ip: "",
      port: "",
      mac: "",
      username: "",
      password: "",
      vendor_name: "",
      role: "",
    };

    setDeviceFormData(camera);
    setIsOpenAddCameraModal(true);
  };

  const closeDeviceModal = (close) => {
    if (close) {
      setIsOpenAddCameraModal(false);
    }
  };

  const handleDeviceFormChange = (e) => {
    setDeviceFormData({
      ...deviceFormData,
      [e.target.name]: e.target.value,
    });
  };

  const onDeviceSubmit = (e) => {
    e.preventDefault();

    if (
      deviceFormData.mac &&
      deviceFormData.password &&
      deviceFormData.username
    ) {
      dispatch(addCamera(deviceFormData)).then((result) => {
        if (result) {
          closeDeviceModal(true);
        }
      });
    } else {
      messageService.warning("MAC address, username and password are required");
    }
  };

  const handlePopoverOpen = (event, message) => {
    setPopoverMessage(message);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (timeToBeDisplay = 0) => {
    setTimeout(() => {
      setAnchorEl(null);
    }, timeToBeDisplay);
  };

  return (
    <React.Fragment>
      <Transition.Root show={isOpenAddCameraModal} as={Fragment}>
        <Dialog
          ref={dialogRef}
          as="div"
          className="relative z-10 your-dialog-content-class"
          initialFocus={cancelButtonRef}
          onClose={() => closeDeviceModal(false)}
          static={true}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white rounded-b-lg rounded-t-lg">
                    <div className="sm:flex sm:items-start sm:p-2 sm:pb-2  bg-light-navy rounded-t-lg ">
                      <div className="w-[50%]">
                        <Dialog.Title
                          as="h3"
                          className="pt-0 text-base font-semibold leading-6 text-white"
                        >
                          Add Device
                        </Dialog.Title>
                      </div>
                      <div className="w-[50%] text-white flex justify-end  px-2">
                        <button
                          onClick={() => closeDeviceModal(true)}
                          className="font-semibold text-md hover:text-gray-200  "
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div className="grid  justify-items-center">
                      <form
                        id="cameraForm"
                        className="flex flex-col space-y-2 px-5 py-2 pb-4 "
                        onSubmit={(e) => onDeviceSubmit(e)}
                      >
                        <div className="flex space-x-2">
                          <label className="flex flex-col">
                            User Name:
                            <input
                              type="text"
                              name="username"
                              value={deviceFormData.username}
                              onChange={handleDeviceFormChange}
                              className="text-black border-solid border-2 rounded border-gray-200 p-1"
                            />
                          </label>
                          <label className="flex flex-col ">
                            Password:
                            <input
                              type="password"
                              name="password"
                              value={deviceFormData.password}
                              onChange={handleDeviceFormChange}
                              className="text-black border-solid border-2 rounded border-gray-200 p-1"
                            />
                          </label>
                        </div>
                        <div className="flex space-x-2">
                          <label className="flex flex-col">
                            Port:
                            <input
                              type="text"
                              name="port"
                              value={deviceFormData.port}
                              onChange={handleDeviceFormChange}
                              className="text-black border-solid border-2 rounded border-gray-200 p-1"
                            />
                          </label>
                          <label className="flex flex-col">
                            Mac Address:
                            <input
                              type="text"
                              name="mac"
                              value={deviceFormData.mac}
                              onChange={handleDeviceFormChange}
                              className="text-black border-solid border-2 rounded border-gray-200 p-1"
                            />
                          </label>
                        </div>
                        <div className="flex space-x-2">
                          <label className="flex flex-col">
                            Camera Name:
                            <input
                              type="text"
                              name="name"
                              value={deviceFormData.name}
                              onChange={handleDeviceFormChange}
                              className="text-black border-solid border-2 rounded border-gray-200 p-1"
                            />
                          </label>
                          <label className="flex flex-col">
                            Vendor Name:
                            <input
                              type="text"
                              name="vendor_name"
                              value={deviceFormData.vendor_name}
                              onChange={handleDeviceFormChange}
                              className="text-black border-solid border-2 rounded border-gray-200 p-1"
                            />
                          </label>
                        </div>
                        <div className="flex space-x-2">
                          <label className="flex flex-col w-[50%]">
                            Role:
                            <select
                              id="role"
                              name="role"
                              value={deviceFormData.role}
                              onChange={handleDeviceFormChange}
                              className="text-black border-solid border-2 rounded border-gray-200 p-1 w-full"
                            >
                              <option value="" disabled>
                                Select One...
                              </option>
                              {cameraRoles.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </label>
                          <label className="flex flex-col w-[50%]">
                            IP Address:
                            <div className="flex space-x-1 ">
                              <input
                                type="text"
                                name="ip"
                                value={deviceFormData.ip}
                                onChange={handleDeviceFormChange}
                                className="text-black border-solid border-2 rounded border-gray-200 p-1 w-[90%]"
                              />
                              <span>
                                <InfoOutlined
                                  className="bg-white rounded-xl"
                                  onMouseEnter={(e) =>
                                    handlePopoverOpen(
                                      e,
                                      "IP address of the camera!"
                                    )
                                  }
                                  onMouseLeave={() => handlePopoverClose(5000)}
                                />
                              </span>
                            </div>
                          </label>
                        </div>
                      </form>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        className=" inline-flex w-full rounded-md  text-white bg-gai-green  px-2 py-2 text-sm font-semibold shadow-sm ring-1 ring-gray-300 hover:bg-emerald-600 sm:w-auto mr-2 border border-gai-green"
                        type="submit"
                        form="cameraForm"
                      >
                        <AddCircleOutlineIcon
                          sx={{ fontSize: 20 }}
                        ></AddCircleOutlineIcon>
                        &nbsp;Add
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="inline-flex w-full rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-200 sm:w-auto mr-2 border border-red-500"
                        onClick={() => closeDeviceModal(true)}
                        ref={cancelButtonRef}
                      >
                        <CancelIcon
                          className="text-red-600"
                          sx={{ fontSize: 20 }}
                        ></CancelIcon>
                        &nbsp;Cancel
                      </button>
                    </div>
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => handlePopoverClose(0)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 0.5, fontSize: 11 }}>
                        <InfoSharpIcon
                          sx={{ fontSize: 15, color: "#30ac64" }}
                        ></InfoSharpIcon>
                        {popoverMessage}
                      </Typography>
                    </Popover>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
});

export default AddCameraModal;
