import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Form, Button, Input, Divider, Popover } from "antd";
import {
  MailOutlined,
  KeyOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";

import authService from "../services/authService";
import { messageService } from "../services/messageService";

import { getNotificationTypes } from "../store/lookup/lookupAction";
import { getCameras } from "../store/camera/cameraAction";
import { getUnit } from "../store/unit/unitAction";
import {
  getAllSalesTransactions,
  getAllDeletedSalesItems,
  getRegisters,
  getPaymentMethods,
} from "../store/sales/salesThunk";

import guardisLogo from "../assets/images/logoG.png";
import loginBackground from "../assets/images/bg.jpg";

const LoginComponent = () => {
  const dispatch = useDispatch();

  const [showSignupForm, setShowSignupForm] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const loginUser = async (email, password) => {
    try {
      const response = await authService.signIn(email, password);
      if (response) {
        let from = location.state?.from?.pathname || "/";

        dispatch(getNotificationTypes());
        dispatch(getCameras());
        dispatch(getUnit());
        dispatch(getAllSalesTransactions());
        dispatch(getAllDeletedSalesItems());
        dispatch(getRegisters());
        dispatch(getPaymentMethods());

        navigate(from, { replace: true });
      }
    } catch (error) {
      messageService.error(`${error.message}`);
    }
  };

  const backgroundStyles = {
    backgroundImage: `url(${loginBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const onLoginFinish = (values) => {
    loginUser(values.email, values.password);
  };

  const onSignupFinish = async (userData) => {
    const response = await authService.signUp(userData);

    setShowSignupForm(false);
    messageService.info(
      `${response} We've sent an activation email to your inbox. Please activate your account to enable access to Guardis.`
    );
  };

  const loginForm = (
    <>
      <h1 className="login-header mb-2">Welcome Back</h1>
      <h2 className="login-subheader mb-10">
        Please enter your details to get started.
      </h2>
      <Form onFinish={onLoginFinish}>
        <Form.Item
          name="email"
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your email.",
            },
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Email is invalid.",
            },
          ]}
        >
          <Input
            id="email"
            placeholder="Email"
            maxLength="50"
            size="large"
            prefix={<MailOutlined className="mr-2" />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your password.",
            },
          ]}
        >
          <Input.Password
            id="password"
            placeholder="Password"
            size="large"
            prefix={<KeyOutlined className="mr-2" />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="login-button"
            block
            size="large"
            type="primary"
            htmlType="submit"
          >
            Login
          </Button>
        </Form.Item>
      </Form>

      <Divider className="login-divider">OR</Divider>

      <div className="flex flex-wrap justify-center items-center">
        <h2 className="login-subheader">Don't have an account?</h2>
        <Button
          className="login-link"
          type="link"
          size="large"
          onClick={() => setShowSignupForm(true)}
        >
          Create an account
        </Button>
      </div>
    </>
  );

  const signupForm = (
    <>
      <h1 className="login-header mb-10">Create an account</h1>
      <Form onFinish={onSignupFinish}>
        <Form.Item
          name="email"
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Enter your email.",
            },
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Email is invalid.",
            },
          ]}
        >
          <Input
            id="email"
            type="email"
            placeholder="Email"
            maxLength="50"
            size="large"
            prefix={<MailOutlined className="w-8" />}
          />
        </Form.Item>

        <Form.Item
          name="password"
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Enter your password.",
            },
            {
              min: 8,
              message: "Must have a minimum length of 8 characters.",
            },
            {
              pattern: /(?=.*?[A-Z])/,
              message: "Must contain at least 1 uppercase letter.",
            },
            {
              pattern: /(?=.*?[a-z])/,
              message: "Must contain at least 1 lowercase letter.",
            },
            {
              pattern: /(?=.*?[0-9])/,
              message: "Must contain at least 1 number.",
            },
            {
              pattern: /(?=.*?[#?!@$%^&*-])/,
              message: "Must contain at least 1 special character.",
            },
          ]}
        >
          <Input.Password
            id="password"
            placeholder="Password"
            size="large"
            prefix={<KeyOutlined className="w-8" />}
          />
        </Form.Item>

        <Form.Item
          name="passwordConfirm"
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Confirm your password.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match."));
              },
            }),
          ]}
          dependencies={["password"]}
        >
          <Input.Password
            id="passwordConfirm"
            placeholder="Password (Confirm)"
            size="large"
            prefix={<KeyOutlined className="w-8" />}
          />
        </Form.Item>

        <Form.Item
          name="machine_id"
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Enter your token.",
            },
          ]}
        >
          <Input
            type="text"
            placeholder="Token"
            size="large"
            prefix={<PinOutlinedIcon className="mr-2" />}
            suffix={
              <Popover
                placement="top"
                content={
                  <div className="w-40">
                    <p className="popover-title">
                      Enter token provided with subscription purchase.
                    </p>
                    <p className="popover-content">
                      Contact customer support if token was not provided.
                    </p>
                  </div>
                }
              >
                <InfoCircleOutlined />
              </Popover>
            }
          />
        </Form.Item>

        <Form.Item>
          <Button
            className="login-button"
            size="large"
            type="primary"
            htmlType="submit"
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>

      <Divider className="login-divider">OR</Divider>

      <div className="flex flex-wrap justify-center items-center">
        <h2 className="login-subheader">Already have an account?</h2>
        <Button
          className="login-link"
          type="link"
          size="large"
          onClick={() => setShowSignupForm(false)}
        >
          Login
        </Button>
      </div>
    </>
  );

  return (
    <div className="h-screen flex app">
      <div className="w-1/2">
        <div style={backgroundStyles} className="w-full h-full">
          <img
            src={guardisLogo}
            style={{
              objectFit: "contain",
              width: "260px",
              paddingTop: "40px",
              paddingLeft: "40px",
            }}
            alt="GuardisAI logo"
          />
        </div>
      </div>
      <div className="w-1/2 flex justify-center items-center">
        <div className="w-full max-w-2xl px-10">
          {showSignupForm ? signupForm : loginForm}
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
