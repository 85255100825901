import { useState } from "react";
import { useSelector } from "react-redux";

import { Button, Drawer, Empty, Spin, Table } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { FaPlayCircle as PlayFilled } from "react-icons/fa";
import { LuCameraOff as CameraOffFilled } from "react-icons/lu";
import moment from "moment";

import SalesVideoClips from "../SalesVideoClips";

export default function SalesDeletedItemsTable() {
  const cameras = useSelector((state) => state.camera.cameraList);
  const cashierCameras = cameras.filter((camera) => camera.role === "Cashier");

  const { allDeletedSalesItems, loadingAllDeletedSalesItems } = useSelector(
    (state) => state.sales
  );

  const [selectedDeletedItem, setSelectedDeletedItem] = useState(null);
  const [openVideoClipsDrawer, setOpenVideoClipsDrawer] = useState(false);

  const renderTimestamp = (timestamp) => {
    return (
      <div className="flex flex-wrap gap-1 items-center">
        {moment(timestamp, "YYYY-MM-DD HH:mm:ss").format("MMMM Do YYYY")}
        <div className="dot"></div>
        {moment(timestamp, "YYYY-MM-DD HH:mm:ss").format("h:mm A")}
      </div>
    );
  };

  const deletedItemsColumns = [
    {
      title: "Item UPC",
      dataIndex: "upc",
      key: "item_upc",
      fixed: "left",
      render: (upc) => <p className="font-mono font-light">{upc}</p>,
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "item_name",
    },
    {
      title: "Date and Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp) => renderTimestamp(timestamp),
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        moment(a.timestamp, "YYYY-MM-DD HH:mm:ss").valueOf() -
        moment(b.timestamp, "YYYY-MM-DD HH:mm:ss").valueOf(),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "item_quantity",
      align: "center",
      width: 100,
      render: (quantity) => <p className="font-mono font-light">{quantity}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      width: 100,
      render: (price) => <p className="font-mono font-light">${price}</p>,
      sorter: (a, b) => parseFloat(a.total) - parseFloat(b.total),
    },
    {
      title: "Play Video Clip",
      key: "actions",
      width: 160,
      fixed: "right",
      render: (_, deletedItem) => (
        <Button
          className="text-guardis-400 dark:text-guardis-500"
          type="text"
          icon={<PlayFilled size={18} />}
          onClick={() => {
            setOpenVideoClipsDrawer(true);
            setSelectedDeletedItem(deletedItem);
          }}
        />
      ),
    },
  ];

  return (
    <div className="sales-table-container">
      <div className="sales-deleted-items-table">
        <Table
          sticky
          size="middle"
          style={{ width: "100%", maxHeight: "70vh" }}
          scroll={{ x: "max-content", y: "calc(70vh - 10rem)" }}
          pagination={{
            position: ["bottomCenter"],
          }}
          dataSource={allDeletedSalesItems}
          columns={deletedItemsColumns}
          rowKey="deleted_sales_item_id"
          locale={{
            emptyText: () => {
              if (loadingAllDeletedSalesItems) {
                return <Spin size="large" />;
              }

              return (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No sales transcations found."}
                />
              );
            },
          }}
        />

        <Drawer
          classNames={{ body: "p-0 overflow-hidden h-full" }}
          size="large"
          title={
            <div className="flex flex-row gap-3 items-center text-lg">
              <h2 className="text-neutral-500 dark:text-neutral-400 ">
                Deleted Sales Item
              </h2>
              <RightOutlined className="text-neutral-500 dark:text-neutral-400" />
              <div>{selectedDeletedItem?.name}</div>
            </div>
          }
          open={openVideoClipsDrawer}
          onClose={() => {
            setOpenVideoClipsDrawer(false);
            setSelectedDeletedItem(null);
          }}
        >
          <div className="h-full flex flex-col bg-neutral-50 dark:bg-neutral-950">
            {selectedDeletedItem && (
              <div className="grid grid-cols-5 p-6 border-b dark:border-neutral-700 shadow-sm bg-white dark:bg-neutral-900">
                <div className="space-y-2 col-span-2">
                  <h4 className="w-24">Date and Time</h4>
                  <p>{renderTimestamp(selectedDeletedItem.timestamp)}</p>
                </div>

                <div className="space-y-2">
                  <h4 className="w-24">Price</h4>
                  <p className="font-mono font-light">
                    ${selectedDeletedItem.price}
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="w-24">Quantity</h4>
                  <p className="font-mono font-light">
                    {selectedDeletedItem.quantity}
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="w-24">UPC</h4>
                  <p className="font-mono font-light">
                    {selectedDeletedItem.upc}
                  </p>
                </div>
              </div>
            )}

            {!cameras && (
              <Empty
                className="py-5 bg-transparent"
                image={
                  <CameraOffFilled className="text-neutral-200 text-8xl stroke-1" />
                }
                description={<h3>No Cashier Cameras Found</h3>}
              />
            )}

            {cashierCameras.length > 0 && selectedDeletedItem && (
              <div className="h-full flex-1 overflow-hidden">
                <SalesVideoClips
                  salesType="deleted item"
                  cameras={cashierCameras}
                  timestamp={moment(
                    selectedDeletedItem.timestamp,
                    "YYYY-MM-DD HH:mm:ss"
                  ).toDate()}
                />
              </div>
            )}
          </div>
        </Drawer>
      </div>
    </div>
  );
}
