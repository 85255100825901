import originalAxios from "axios";
import authService from "../services/authService";

const axios = originalAxios.create({
  //baseURL: authService.getUnitUrl(),
});

// Add request interceptor
axios.interceptors.request.use(
  async (config) => {
    const token = await authService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token.customToken}`;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    console.error("Request interceptor error", error);
    return Promise.reject(error);
  }
);

// Add response interceptor
axios.interceptors.response.use(
  (response) => {
    // Handle successful response
    return response;
  },
  (error) => {
    console.error("Response interceptor error", error);
    return Promise.reject(error);
  }
);

export default axios;
