import { salesActions } from "./salesSlice";
import salesAPI from "../../api/sales";
import registersAPI from "../../api/registers";
import { notificationService } from "../../services/notificationService";
import { retryRequest } from "../../utils/storeUtils";

export const getAllSalesTransactions = () => {
  return async (dispatch) => {
    try {
      dispatch(salesActions.setLoadingAllTransactions(true));

      const response = await retryRequest(() =>
        salesAPI.getAllSalesTransactions()
      );

      if (response.status) {
        dispatch(salesActions.setAllTransactions(response.data));
      } else {
        notificationService.error({
          message: "Error with Sales Transactions API",
          description: response.error?.message,
        });
      }
    } catch (error) {
      notificationService.error({
        message: "Error with retrieving sales transactions data",
        description: error.message,
      });
    } finally {
      dispatch(salesActions.setLoadingAllTransactions(false));
    }
  };
};

export const getFilteredSalesTransactions = (filters) => {
  return async (dispatch) => {
    try {
      dispatch(salesActions.setLoadingFilteredTransactions(true));

      const response = await retryRequest(() =>
        salesAPI.getFilteredSalesTransactions(filters)
      );

      if (response.status) {
        dispatch(salesActions.setFilteredTransactions(response.data));
      } else {
        notificationService.error({
          message: "Error with filtered sales transactions API",
          description: response.error?.message,
        });
      }
    } catch (error) {
      notificationService.error({
        message: "Error with filtering sales transactions data",
        description: error.message,
      });
    } finally {
      dispatch(salesActions.setLoadingFilteredTransactions(false));
    }
  };
};

export const addFilter = (filter) => {
  return async (dispatch) => {
    dispatch(salesActions.addFilter(filter));
  };
};

export const updateFilter = (filter) => {
  return async (dispatch) => {
    dispatch(salesActions.updateFilter(filter));
  };
};

export const deleteFilter = (filter) => {
  return async (dispatch) => {
    dispatch(salesActions.deleteFilter(filter));
  };
};

export const setFilters = (filters) => {
  return async (dispatch) => {
    dispatch(salesActions.setFilters(filters));
  };
};

export const clearFilters = () => {
  return async (dispatch) => {
    dispatch(salesActions.clearFilters());
  };
};

export const getAllDeletedSalesItems = () => {
  return async (dispatch) => {
    try {
      dispatch(salesActions.setLoadingAllDeletedSalesItems(true));

      const response = await retryRequest(() =>
        salesAPI.getAllDeletedSalesItems()
      );

      if (response.status) {
        dispatch(salesActions.setAllDeletedSalesItems(response.data));
      } else {
        notificationService.error({
          message: "Error with Deleted Sales Items API",
          description: response.error?.message,
        });
      }
    } catch (error) {
      notificationService.error({
        message: "Error with retrieving deleted sales items data",
        description: error.message,
      });
    } finally {
      dispatch(salesActions.setLoadingAllDeletedSalesItems(false));
    }
  };
};

export const getRegisters = () => {
  return async (dispatch) => {
    try {
      const response = await retryRequest(() => registersAPI.getAllRegisters());

      if (response.status) {
        dispatch(salesActions.setRegisters(response.data));
      } else {
        notificationService.error({
          message: "Error with Registers API",
          description: response.error?.message,
        });
      }
    } catch (error) {
      notificationService.error({
        message: "Error retrieving registers data",
        description: error.message,
      });
    }
  };
};

export const getPaymentMethods = () => {
  return async (dispatch) => {
    try {
      const response = await retryRequest(() => salesAPI.getPaymentMethods());

      if (response.status) {
        dispatch(salesActions.setPaymentMethods(response.data));
      } else {
        notificationService.error({
          message: "Error with payment methods API",
          description: response.error?.message,
        });
      }
    } catch (error) {
      notificationService.error({
        message: "Error retrieving payment methods data",
        description: error.message,
      });
    }
  };
};
