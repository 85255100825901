import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Modal, Form, Input, Select } from "antd";
import CircleIcon from "@mui/icons-material/Circle";

import LiveVideoPlayer from "../LivePlayer";
import authService from "../../services/authService";
import { updateCamera } from "../../store/camera/cameraAction";

export default function EditCameraModal({ camera = {}, open, onOk, onCancel }) {
  const dispatch = useDispatch();
  const actionTypes = useSelector((state) => state.lookup.actionTypes);
  const cameraRoles = useSelector((state) => state.lookup.cameraRoles);
  const intentList = useSelector((state) => state.lookup.intentList);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [modalWidth, setModalWidth] = useState("50%");

  const [originalCameraData, setOriginalCameraData] = useState(camera);
  const [modifiedCameraData, setModifiedCameraData] = useState(camera);

  useEffect(() => {
    const updateCurrentCamera = () => {
      setOriginalCameraData(camera);
      setModifiedCameraData(camera);
    };

    updateCurrentCamera();
  }, [camera]);

  useEffect(() => {
    const updateModalWidth = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1920) {
        // bigger desktop
        setModalWidth("40%");
      } else if (windowWidth >= 1374) {
        // desktop
        setModalWidth("50%");
      } else if (windowWidth >= 768) {
        // tablets
        setModalWidth("60%");
      } else {
        // mobile
        setModalWidth("95%");
      }
    };

    updateModalWidth();
    window.addEventListener("resize", updateModalWidth);
    return () => window.removeEventListener("resize", updateModalWidth);
  });

  const handleOnOk = async () => {
    form.submit();

    try {
      await form.validateFields();
      dispatch(updateCamera(originalCameraData, modifiedCameraData));
      onOk();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnCancel = () => {
    onCancel();
    navigate("/setup");
  };

  const getIntentLabelColor = (value) => {
    const intent = intentList.find((option) => option.value === value);
    return intent ? intent.color : "#FFFFFF";
  };

  const handleChangeCameraName = (name) => {
    let updatedCameraData = { ...modifiedCameraData };
    updatedCameraData.name = name;
    setModifiedCameraData(updatedCameraData);
  };

  const handleChangeCameraRole = (role) => {
    let updatedCameraData = { ...modifiedCameraData };
    updatedCameraData.role = role;
    setModifiedCameraData(updatedCameraData);
  };

  const handleChangeAdditionalContext = (additionalContext) => {
    let updatedCameraData = { ...modifiedCameraData };
    updatedCameraData.additional_context = additionalContext;
    setModifiedCameraData(updatedCameraData);
  };

  const handleChangeCameraActions = (actions) => {
    let updatedCameraData = { ...modifiedCameraData };
    updatedCameraData.action_types = actions.map((actionID) => {
      const action = actionTypes.find(
        (actionType) => actionType.id === actionID
      );
      return action;
    });
    setModifiedCameraData(updatedCameraData);
  };

  const handleChangeActionTypeIntent = (actionType, newIntent) => {
    let updatedCameraData = { ...modifiedCameraData };
    updatedCameraData.action_types = updatedCameraData.action_types.map(
      (originalActionType) => {
        if (originalActionType.name === actionType.name) {
          let updatedActionType = { ...originalActionType };
          updatedActionType.intent = newIntent;
          return updatedActionType;
        }

        return originalActionType;
      }
    );
    setModifiedCameraData(updatedCameraData);
  };

  if (Object.keys(modifiedCameraData).length === 0) {
    return null;
  }

  return (
    <Modal
      id="EditCameraModal"
      key="EditCameraModal"
      wrapClassName="EditCameraModal"
      classNames={{
        mask: "modal-mask",
        header: "modal-header",
        content: "modal-content",
        body: "border-y dark:border-neutral-700 h-[70vh]",
        footer: "modal-footer",
      }}
      open={open}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      title={<h2 className="modal-title">Edit Camera</h2>}
      okText="Save"
      okButtonProps={{
        block: true,
        className: "m-0",
        form: "EditCameraForm",
        key: "submit",
        htmlType: "submit",
      }}
      cancelButtonProps={{ block: true }}
      destroyOnClose
      centered
      keyboard
      width={modalWidth}
    >
      <div className="h-full flex flex-row">
        <Form
          id="EditCameraForm"
          form={form}
          preserve={false}
          className="w-full flex flex-col px-10 pb-64 gap-4 overflow-y-scroll"
        >
          {/* EDIT CAMERA NAME */}
          <div id="cameraName" className="flex flex-col gap-1 pt-4">
            <h3>Name</h3>
            <Form.Item
              hasFeedback
              name="cameraName"
              initialValue={originalCameraData.name}
              rules={[
                {
                  required: true,
                  message: "Camera name is required.",
                },
              ]}
            >
              <Input
                allowClear
                variant="filled"
                placeholder="Enter Name for Camera"
                onChange={(e) => handleChangeCameraName(e.target.value)}
              />
            </Form.Item>
          </div>

          {/* EDIT CAMERA ROLE */}
          <div id="cameraRole" className="flex flex-col gap-1">
            <h3>Role</h3>
            <Form.Item
              hasFeedback
              name="cameraRole"
              initialValue={originalCameraData.role}
              rules={[
                {
                  required: true,
                  message: "Role is required.",
                },
              ]}
            >
              <Select
                allowClear
                variant="filled"
                placeholder="Select Role for Camera"
                options={cameraRoles}
                onChange={handleChangeCameraRole}
              />
            </Form.Item>
          </div>

          {/* EDIT CAMERA ADDITIONAL CONTEXT */}
          <div id="additionalContext" className="flex flex-col gap-1">
            <h3>Additional Context</h3>
            <Form.Item
              hasFeedback
              name="additionalContext"
              initialValue={originalCameraData.additional_context}
              rules={[
                {
                  required: true,
                  message: "Additional context is required.",
                },
              ]}
            >
              <Input.TextArea
                allowClear
                variant="filled"
                cols={50}
                rows={3}
                maxLength={100}
                placeholder="Enter Additional Context for Camera"
                onChange={(e) => handleChangeAdditionalContext(e.target.value)}
              />
            </Form.Item>
          </div>

          {/* SELECT CAMERA ACTIONS */}
          <div id="actionTypes" className="space-y-2">
            <h3>Actions</h3>

            {/* SELECT ACTION TYPES */}
            <div id="selectActionTypes" className="flex flex-col gap-3">
              <h4>Select Action Types</h4>
              <Form.Item
                hasFeedback
                name="actionTypes"
                initialValue={originalCameraData.action_types.map(
                  (actionType) => actionType.id
                )}
                rules={[
                  {
                    required: true,
                    message: "At least one action must be selected.",
                    type: "array",
                  },
                ]}
              >
                <Select
                  allowClear
                  variant="filled"
                  placeholder="Select Actions"
                  mode="multiple"
                  fieldNames={{ label: "name", value: "id" }}
                  options={actionTypes}
                  onChange={handleChangeCameraActions}
                />
              </Form.Item>
            </div>

            <div id="selectIntents" className="flex flex-col gap-3">
              <h4>Select Intent of Action Types</h4>

              <div className="bg-black/[0.04] dark:bg-white/[0.08] rounded-md">
                {modifiedCameraData.action_types.map((actionType) => {
                  const name = actionType.name;
                  const intent = actionType.intent;
                  const key = `${name.toLowerCase()}-select-intent`;

                  return (
                    <div
                      key={key}
                      className="flex flex-row justify-between px-4 py-2 border-b border-neutral-200 dark:border-neutral-800 last:border-b-0"
                    >
                      <p className="w-32 text-sm font-normal">{name}</p>
                      <Form.Item
                        key={key}
                        className="flex-1 max-w-64"
                        initialValue={intent}
                      >
                        <Select
                          key={key}
                          placeholder="Select an Intent"
                          defaultValue={intent}
                          options={intentList}
                          onChange={(value) => {
                            handleChangeActionTypeIntent(actionType, value);
                          }}
                          labelRender={(option) => {
                            const fontColor = getIntentLabelColor(option.value);

                            return (
                              <div className="flex items-center gap-2">
                                <CircleIcon
                                  className="text-[10px]"
                                  sx={{ color: fontColor }}
                                />
                                <div className="font-normal">
                                  {option.label}
                                </div>
                              </div>
                            );
                          }}
                          optionRender={(option) => {
                            const fontColor = getIntentLabelColor(option.value);

                            return (
                              <div className="flex items-center gap-2">
                                <CircleIcon
                                  className="text-[10px]"
                                  sx={{ color: fontColor }}
                                />
                                <div className="font-normal">
                                  {option.label}
                                </div>
                              </div>
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <h3 id="liveFeed">Live Feed</h3>
            <LiveVideoPlayer
              className="border rounded-md overflow-hidden shadow-lg"
              playerID={`player_${modifiedCameraData.mac}`}
              videoURL={`${authService.getUnitUrl()}media/live/${
                modifiedCameraData.mac
              }/output.m3u8`}
              cameraName={modifiedCameraData.name}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
}
