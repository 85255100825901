import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  PieChart,
  Pie,
  ResponsiveContainer,
  CartesianGrid,
  Customized,
} from "recharts";
import { Select, Radio, Tabs } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

import analyticsApi from "../../api/analytics";
import DateRangePickerModal from "../DateRangePickerModal";

import { notificationService } from "../../services/notificationService";

const CameraActivitySummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cameraList = useSelector((state) => state.camera.cameraList);
  const [activitySummaryData, setActivitySummaryData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState("week");
  const [selectedCamera, setSelectedCamera] = useState(null);

  const [filterByMonitoring, setFilterByMonitoring] = useState(false);
  const [openDateRangePickerModal, setOpenDateRangePickerModal] =
    useState(false);

  useEffect(() => {
    const setCamera = () => {
      if (cameraList.length > 0) {
        const firstCamera = cameraList[0];
        setSelectedCamera(firstCamera);
      }
    };

    const fetchData = async () => {
      let newStartDate = new Date(startDate);
      newStartDate.setDate(startDate.getDate() - 7);
      newStartDate.setHours(0, 0, 0, 0);

      let response = await analyticsApi.getActivitySummaryData(
        selectedTab === "week" ? newStartDate : startDate,
        endDate ? endDate : new Date()
      );

      if (response.status) {
        setActivitySummaryData(response.data);
      } else {
        notificationService.error({
          message: "Error Getting Activity Summary Data",
          description: response.error,
        });
      }
    };

    setCamera();
    fetchData();
  }, [startDate, endDate, cameraList, dispatch]);

  useEffect(() => {
    const createPieChartData = () => {
      let chartData = [];

      if (selectedCamera.mac in activitySummaryData) {
        const selectedCameraData = activitySummaryData[selectedCamera.mac];

        chartData = selectedCamera?.action_types
          ?.filter(
            (actionType) =>
              (filterByMonitoring && actionType.intent === "monitoring") ||
              !filterByMonitoring
          )
          .sort((a, b) => {
            if (a.name && b.name) {
              return a.name.localeCompare(b.name);
            }
            return a;
          })
          .filter((actionType) => {
            return selectedCameraData[actionType.id];
          })
          .map((actionType) => {
            return {
              name: actionType.name,
              id: actionType.id,
              value: selectedCameraData[actionType.id],
            };
          });
      }

      return chartData;
    };

    if (selectedCamera && activitySummaryData) {
      setPieChartData(createPieChartData(selectedCamera, activitySummaryData));
    }
  }, [selectedCamera, activitySummaryData, filterByMonitoring]);

  const getStartDate = (timePeriod) => {
    const dateNow = new Date();
    let date;

    switch (timePeriod) {
      case "week":
        date = new Date();
        date.setDate(dateNow.getDate() - 7);

        break;
      case "fifteen":
        date = new Date();
        date.setDate(dateNow.getDate() - 14);
        break;
      case "thirty":
        date = new Date();
        date.setMonth(dateNow.getMonth() - 1);
        break;
      default:
        throw new Error("Filter not implemented!");
    }

    date.setHours(0, 0, 0, 0);
    return date;
  };

  const handleCameraChange = (uuid) => {
    const camera = cameraList.find((camera) => camera.uuid === uuid);
    setSelectedCamera(camera);
  };

  const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    fill,
    payload,
    percent,
    value,
  }) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 25) * cos;
    const my = cy + (outerRadius + 25) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 8;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        <text
          className="fill-white"
          x={x}
          y={y}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {value}
        </text>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={4} fill={fill} stroke="none" />
        <text
          className="fill-neutral-500 dark:fill-neutral-300"
          x={ex + (cos >= 0 ? 1 : -1) * 16}
          y={ey}
          textAnchor={textAnchor}
        >{`${payload.name}`}</text>
        <text
          className="fill-yellow-500 dark:fill-yellow-400"
          x={ex + (cos >= 0 ? 1 : -1) * 32}
          y={ey}
          dy={20}
          textAnchor={textAnchor}
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);

    if (tab !== "custom") {
      const newStartDate = getStartDate(tab);
      setStartDate(newStartDate);
      setEndDate(null);
    }
  };

  const handleTabClick = (tab) => {
    if (tab === "custom") {
      setOpenDateRangePickerModal(true);
    }
  };

  const handleDateRangeOnOk = (dateRange) => {
    setOpenDateRangePickerModal(false);
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  const getFilterModel = (timePeriod) => {
    const startDate = getStartDate(timePeriod);
    const endDate = new Date();

    const model = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:00:00"),
      end_time: moment(endDate).format("YYYY-MM-DD 23:59:59"),
      camera_id: selectedCamera.mac,
    };

    return model;
  };

  const handlePieSectionClick = (data) => {
    const action_type_id = data.payload.id;
    const model = getFilterModel(selectedTab);
    model.action_type_ids = [action_type_id];

    navigate("/events", {
      state: {
        filterModel: model,
      },
    });
  };

  const EmptyStateMessage = () => (
    <g>
      <text
        x="50%"
        y="45%"
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#B0B0B0"
        fontSize="18px"
        fontWeight={300}
      >
        {selectedCamera
          ? `No activity detected for ${selectedCamera.name}.`
          : " No activity detected."}
      </text>
    </g>
  );

  return (
    <div className="card min-w-1/3 flex flex-1 flex-col gap-4 pt-6 pb-10">
      <h2 className="px-4">Camera Activity Summary</h2>

      <Tabs
        type="card"
        centered
        value={selectedTab}
        tabBarStyle={{ margin: 0, marginBottom: 0 }}
        items={[
          { key: "week", label: "Last 7 Days" },
          { key: "fifteen", label: "Last 15 Days" },
          { key: "thirty", label: "Last 30 Days" },
          { key: "custom", label: "Custom", icon: <CalendarOutlined /> },
        ]}
        onChange={(key) => handleTabChange(key)}
        onTabClick={(key) => handleTabClick(key)}
      />

      <div className="w-full flex gap-4 px-8">
        <Select
          className="w-1/2"
          fieldNames={{ label: "name", value: "uuid" }}
          placeholder={"Select a Camera"}
          onChange={(camera) => {
            handleCameraChange(camera);
          }}
          options={cameraList}
          value={selectedCamera?.uuid}
          showSearch
        />

        <div className="w-1/2">
          <Radio.Group
            className="w-full"
            defaultValue={"all"}
            onChange={(e) => {
              setFilterByMonitoring(e.target.value === "monitoring");
            }}
            buttonStyle="solid"
          >
            <Radio.Button className="w-1/2 text-center" value="all">
              All
            </Radio.Button>
            <Radio.Button className="w-1/2 text-center" value="monitoring">
              Monitoring
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className="w-full h-full flex px-8">
        <ResponsiveContainer height={400}>
          <PieChart>
            {pieChartData.length === 0 && (
              <Customized component={<EmptyStateMessage />} />
            )}

            <CartesianGrid strokeDasharray="4" strokeWidth={1} />
            <defs>
              <linearGradient id={"pie"} x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="25%" stopColor="#4ade80" />
                <stop offset="100%" stopColor="#22c55e" />
              </linearGradient>
            </defs>
            <Pie
              data={pieChartData}
              onClick={handlePieSectionClick}
              innerRadius="20%"
              outerRadius="60%"
              fill="url(#pie)"
              dataKey="value"
              nameKey="name"
              label={CustomLabel}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <DateRangePickerModal
        open={openDateRangePickerModal}
        onOk={handleDateRangeOnOk}
        onCancel={() => setOpenDateRangePickerModal(false)}
      />
    </div>
  );
};

export default CameraActivitySummary;
