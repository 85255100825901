import { createSlice } from "@reduxjs/toolkit";

const salesSlice = createSlice({
  name: "sales",
  initialState: {
    // all sales transactions
    allTransactions: [],
    loadingAllTransactions: false,
    allTransactionsCount: 0,

    // filtered sales transactions
    filteredTransactions: [],
    loadingFilteredTransactions: false,
    filteredTransactionsCount: 0,

    // filters for sales transactions
    filters: [],
    filtersCount: 0,

    allDeletedSalesItems: [],
    loadingAllDeletedSalesItems: false,
    allDeletedSalesItemsCount: 0,

    registers: [],
    paymentMethods: [],
  },
  reducers: {
    setAllTransactions(state, action) {
      const newTransactions = action.payload;

      state.allTransactions = newTransactions;
      state.allTransactionsCount = newTransactions.length;
    },
    clearAllTransactions(state) {
      state.allTransactions = [];
      state.allTransactionsCount = 0;
    },
    setLoadingAllTransactions(state, action) {
      state.loadingAllTransactions = action.payload;
    },

    setFilteredTransactions(state, action) {
      const newFilteredTransactions = action.payload;

      state.filteredTransactions = newFilteredTransactions;
      state.filteredTransactionsCount = newFilteredTransactions.length;
    },
    clearFilteredTransactions(state) {
      state.filteredTransactions = [];
      state.filteredTransactionsCount = 0;
    },
    setLoadingFilteredTransactions(state, action) {
      state.loadingFilteredTransactions = action.payload;
    },

    addFilter(state, action) {
      const addedFilter = action.payload;

      state.filters.push(addedFilter);
      state.filtersCount += 1;
    },
    updateFilter(state, action) {
      const updatedFilter = action.payload;

      state.filters.forEach((filter) => {
        if (updatedFilter.id === filter.id) {
          filter.key = updatedFilter.key;
          filter.operator = updatedFilter.operator;
          filter.value = updatedFilter.value;
        }
      });
    },
    deleteFilter(state, action) {
      const deletedFilter = action.payload;

      state.filters = state.filters((filter) => filter.id !== deletedFilter.id);
    },
    setFilters(state, action) {
      const newFilters = action.payload;

      state.filters = newFilters;
      state.filtersCount = newFilters.length;
    },
    clearFilters(state) {
      state.filters = [];
      state.filtersCount = 0;
    },

    setAllDeletedSalesItems(state, action) {
      const newDeletedSalesItems = action.payload;

      state.allDeletedSalesItems = newDeletedSalesItems;
      state.allDeletedSalesItemsCount = newDeletedSalesItems.length;
    },
    clearAllDeletedSalesItems(state) {
      state.allDeletedSalesItems = [];
      state.allDeletedSalesItemsCount = 0;
    },
    setLoadingAllDeletedSalesItems(state, action) {
      state.loadingAllDeletedSalesItems = action.payload;
    },

    setPaymentMethods(state, action) {
      const newPaymentMethods = action.payload;

      state.paymentMethods = newPaymentMethods;
    },
    clearPaymentMethods(state) {
      state.paymentMethods = [];
    },

    setRegisters(state, action) {
      state.registers = action.payload;
    },
  },
});

export const salesActions = salesSlice.actions;
export default salesSlice;
