import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

const DashboardChartTooltip = ({
  className = "",
  title = "",
  content = "",
  iconColor = null,
}) => {
  return (
    <Tooltip
      color="#525252"
      title={
        <div className="flex flex-col gap-1 text-base px-1 py-2">
          <p className="text-guardis-300 font-semibold">{`${title}`}</p>
          <p className="text-neutral-100">{`${content}`}</p>
        </div>
      }
    >
      <div className={`flex justify-center content-center ${className}`}>
        <InfoCircleTwoTone
          style={{ fontSize: "20px" }}
          twoToneColor={iconColor ? iconColor : "#2DAB64"}
        />
      </div>
    </Tooltip>
  );
};

export default DashboardChartTooltip;
