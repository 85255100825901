import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ResponsiveContainer, Area, AreaChart } from "recharts";

import TransferWithinAStationOutlinedIcon from "@mui/icons-material/TransferWithinAStationOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";

import analyticsApi from "../../api/analytics";
import DashboardChartTooltip from "../DashboardChartTooltip";

import { notificationService } from "../../services/notificationService";

export default function BadgesGraph({ selectedDate = new Date() }) {
  const dispatch = useDispatch();
  const [aggregateData, setAggregateData] = useState([]);
  const [hiddenPercentChange, setHiddenPercentChange] = useState(false);

  useEffect(() => {
    const getChartData = async (startDate) => {
      let endDate;

      startDate.setHours(0, 0, 0, 0);

      if (isToday(startDate)) {
        endDate = new Date();
        endDate.setHours(endDate.getHours() + 1, 0, 0, 0);
        setHiddenPercentChange(true);
      } else {
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);
        setHiddenPercentChange(false);
      }

      let response = await analyticsApi.getBadgesGraphData(startDate, endDate);
      if (response.status) {
        setAggregateData(response.result);
      } else {
        notificationService.error({
          message: "Error Getting Badges Data",
          description: response.error,
        });
      }
    };
    getChartData(selectedDate);
  }, [dispatch, selectedDate]);

  const getAverage = (data, key) => {
    const result = getSum(data, key) / data.length;

    return result.toFixed(2);
  };

  const getSum = (data, key) => {
    return data.reduce((acc, item) => acc + item[key], 0);
  };

  const getPercentageChange = (data, key) => {
    if (data.length === 0) return 0;

    const previusHour = data[data.length - 2][key];
    const lastHour = data[data.length - 1][key];

    if (previusHour === 0) return 0;

    return ((lastHour - previusHour) / previusHour) * 100;
  };

  const getPercentChangeElement = (data, key) => {
    const change = getPercentageChange(data, key);

    if (change > 0) {
      return (
        <div className="px-1 bg-transparent">
          <p className="text-xs font-extrabold text-white">{`↑ ${change.toFixed(
            2
          )}%`}</p>
        </div>
      );
    } else if (change < 0) {
      return (
        <div className="px-1 bg-transparent">
          <p className="text-xs font-extrabold text-white">{`↓ ${Math.abs(
            change
          ).toFixed(2)}%`}</p>
        </div>
      );
    } else {
      return (
        <div className="px-1 bg-transparent">
          <p className="text-xs font-extrabold text-white">0%</p>
        </div>
      );
    }
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const badgesClassName =
    "w-full flex flex-col justify-between gap-1 px-2 py-3 border shadow-lg rounded-xl";
  const badgesIconClassName = "p-1 mx-2 border-2 rounded-full";
  const badgesIconSx = { fontSize: 36 };

  const badgesData = [
    {
      name: "Foot Traffic",
      icon: (
        <TransferWithinAStationOutlinedIcon
          className={badgesIconClassName}
          sx={badgesIconSx}
        />
      ),
      className:
        "bg-gradient-to-tr from-guardis-400 to-guardis-800 border-green-400",
      tooltipContent:
        "Displays the total number of people who have entered and exited the location for the day with a graph of hourly counts. Foot Traffic is the estimated number of people who enter and exit this location. This is calculated by taking the greater of the counts of entries and exits.",
      color: "#16a34a",
      data: `${getSum(aggregateData, "Foot Traffic")}`,
    },
    {
      name: "Item Selection Rate",
      icon: (
        <AddShoppingCartOutlinedIcon
          className={badgesIconClassName}
          sx={badgesIconSx}
        />
      ),
      className: "bg-gradient-to-tr from-yellow-600 to-red-700 border-red-600",
      tooltipContent:
        "Displays the average rate of item selection at this location for the day. Item selection rate is the estimated rate of item selection at this location. This is calculated by dividing the count of item selection events by the count of customer browsing events. Defaults to 0 for no customer browsing events.",
      color: "#c2410c",
      data: `${getAverage(aggregateData, "Item Selection Rate")}%`,
    },
    {
      name: "Sales Rate",
      icon: (
        <PointOfSaleOutlinedIcon
          className={badgesIconClassName}
          sx={badgesIconSx}
        />
      ),
      className: "bg-gradient-to-tr from-sky-400 to-indigo-600 border-sky-600",
      tooltipContent:
        "Displays the average rate of sales at this location for the day with a graph of hourly rates. Sales rate is the estimated rate of sales at this location. This is calculated by dividing the count of sale events by foot traffic. Defaults to 0 for no foot traffic.",
      color: "#0284c7",
      data: `${getAverage(aggregateData, "Sales Rate")}%`,
    },
  ];

  return (
    <div className="flex flex-row flex-wrap md:flex-nowrap gap-3 lg:gap-12 justify-between">
      {badgesData.map((badge) => (
        <div
          key={`badges-graph-${badge.name}`}
          className={`${badgesClassName} ${badge.className}`}
        >
          <div className="flex flex-row justify-center gap-4">
            <h2 className="text-neutral-100 text-center">
              {badge.icon}
              {badge.name}
            </h2>
            <div className="flex justify-center">
              <DashboardChartTooltip
                title={badge.name}
                content={badge.tooltipContent}
                iconColor={badge.color}
              />
            </div>
          </div>

          <h1 className="text-white text-center font-semibold">{badge.data}</h1>

          <div className="w-full h-16">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={aggregateData}>
                <Area
                  type="monotone"
                  dataKey={badge.name}
                  stroke="#FFFFFF"
                  fill={badge.color}
                  strokeWidth={2}
                  dot={false}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          <h3 className="w-full h-5 flex flex-row justify-end">
            {hiddenPercentChange &&
              getPercentChangeElement(aggregateData, badge.name)}
          </h3>
        </div>
      ))}
    </div>
  );
}
