import React, { useState } from "react";

import { Modal, DatePicker } from "antd";
import dayjs from "dayjs";

export default function DateRangePickerModal({ open, onOk, onCancel }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Modal
      title="Select a Date Range"
      open={open}
      onOk={() => onOk([startDate, endDate])}
      okButtonProps={{ disabled: !(startDate && endDate) }}
      onCancel={onCancel}
    >
      <DatePicker.RangePicker
        className="w-full my-4"
        size="large"
        value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
        onChange={(newDates) => {
          if (newDates && newDates.length === 2) {
            const newStartDate = newDates[0].toDate();
            const newEndDate = newDates[1].toDate();

            newStartDate.setHours(0, 0, 0, 0);
            newEndDate.setHours(23, 59, 59, 59);

            setStartDate(newStartDate);
            setEndDate(newEndDate);
          }
        }}
      />
    </Modal>
  );
}
