import React from "react";

import { Tooltip } from "antd";
import { RiErrorWarningFill as Warning } from "react-icons/ri";

import LiveVideoPlayer from "../LivePlayer";

const VideoList = React.memo(({ cameraList, createVideoUrl, setMainVideo }) => {
  return (
    <div className="h-full flex-1 overflow-y-auto snap-center">
      <div className="flex flex-row gap-8 flex-wrap justify-center px-12 py-8">
        {cameraList?.map((camera, index) => {
          const videoUrl = createVideoUrl(camera.mac);
          console.log(camera);
          return (
            <div
              key={index}
              className="rounded-xl overflow-hidden relative hover:ring-4 hover:ring-offset-2 hover:ring-guardis-400 transition-all duration-300"
            >
              <div onClick={() => setMainVideo(camera)}>
                <LiveVideoPlayer
                  playerID={`player_${camera.mac}`}
                  videoURL={videoUrl}
                  cameraName={camera.name}
                />
              </div>
              <div className="px-2 py-1 rounded-lg bg-neutral-900/[0.9] absolute bottom-2 left-2">
                <div className="text-sm text-neutral-200 font-medium">
                  {camera.name}
                </div>
              </div>

              {!camera.ishealthy && (
                <Tooltip
                  title={
                    <>
                      <div className="font-mono text-xs">{camera.name}</div>
                      <div className="text-sm">has an issue.</div>
                    </>
                  }
                  placement="bottomLeft"
                >
                  <div className="absolute top-2 right-2">
                    <Warning className="text-red-500" size={24} />
                  </div>
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default VideoList;
