import React, { createRef, useState } from "react";
import ReactPlayer from "react-player";

import { Spin } from "antd";

const LiveVideoPlayer = React.memo(
  ({
    className,
    playerID,
    cameraName,
    videoURL,
    width = "100%",
    height = "100%",
  }) => {
    const currentVideoPlayer = createRef();
    const [isBuffering, setIsBuffering] = useState(false);

    return (
      <div className="h-full w-full bg-neutral-150 dark:bg-neutral-750">
        <Spin spinning={isBuffering} size="large">
          <ReactPlayer
            className={className}
            id={playerID}
            ref={currentVideoPlayer}
            url={videoURL}
            width={width}
            height={height}
            playing={true}
            preload="auto"
            autoPlay={true}
            muted={true}
            loop={false}
            config={{
              file: {
                hlsOptions: {
                  maxBufferLength: 20, // or 15 or 20 based on tests
                  maxMaxBufferLength: 30,
                  maxBufferSize: 60 * 1000 * 1000,
                  maxBufferHole: 2.5,
                  highBufferWatchdogPeriod: 10,
                  maxFragLookUpTolerance: 2.5,
                  enableWorker: true,
                  lowLatencyMode: true,
                  progressive: true,
                  testBandwidth: true,
                  liveDurationInfinity: true,
                  autoStartLoad: true,
                  backBufferLength: Infinity,
                  frontBufferFlushThreshold: Infinity,
                  nudgeOffset: 0.1,
                  nudgeMaxRetry: 10,
                  preferManagedMediaSource: true,
                  enableSoftwareAES: true,
                  capLevelToPlayerSize: true,
                  fragLoadingMaxRetry: 10,
                  manifestLoadingMaxRetry: 10,
                  levelLoadingMaxRetry: 10,
                  appendErrorMaxRetry: 10,
                  startLevel: -1,
                },
              },
            }}
            onBuffer={() => setIsBuffering(true)}
            onBufferEnd={() => setIsBuffering(false)}
            onProgress={(progress) => {
              const playedProgressSeconds = progress.playedSeconds;
              const videoDurationSecond =
                currentVideoPlayer.current.getDuration();
              const realtimeDelay = videoDurationSecond - playedProgressSeconds;

              if (playedProgressSeconds > 0 && realtimeDelay >= 40) {
                const timeBehindRealTime = 20;
                console.log(
                  `There was a delay in live video of: ${Math.round(
                    realtimeDelay / 60
                  )} minutes, in camera:${cameraName} , it was automatically adjusted to the last ${timeBehindRealTime} seconds`
                );
                const newPlayTime = videoDurationSecond - timeBehindRealTime;
                currentVideoPlayer.current.seekTo(newPlayTime);
              }
            }}
            onError={(...args) => {
              try {
                var error = JSON.stringify(args[0]);

                if (error === '"hlsError"') {
                  error = `${error}: { reason:${JSON.stringify(
                    args[1].reason
                  )}, details:${JSON.stringify(
                    args[1].details
                  )}, type:${JSON.stringify(
                    args[1].type
                  )}, fatal:${JSON.stringify(
                    args[1].fatal
                  )}, url:${JSON.stringify(args[1].url)}}`;
                }
                console.log(
                  `Camera:${cameraName},There is a error with the video: ${error}`
                );
              } catch {
                console.log(
                  `Camera:${cameraName},There is a error with the video: ${JSON.stringify(
                    args
                  )}`
                );
              }
            }}
          />
          <input type="hidden" value={videoURL} />
        </Spin>
      </div>
    );
  }
);

export default LiveVideoPlayer;
